var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"processing-details"},[_c('div',{staticClass:"processing-details__supplier"},[_c('div',{staticClass:"supplier-input"},[(!_vm.invoice?.order?.distributor?.id)?_c('ez-autocomplete',{ref:"autocomplete",attrs:{"id":"supplierInput","placeholder":`Enter ${_vm.$t('global.distributor')} Name`,"source":`/admin/search/distributors`,"hasConnectedParam":false,"requestParams":{
          venueId: _vm.invoice?.venue?.id,
          sameCurrency: 'true',
          fromGroup: true,
          includeTemp: true,
        },"searchProperty":"name","label":`${_vm.$t('global.distributor')}`},on:{"selected":_vm.onSupplierSelect,"results":_vm.selectFirstAvailableSupplier},scopedSlots:_vm._u([{key:"firstResult",fn:function({ input }){return [_c('li',[_c('div',{staticClass:"create-new-supplier"},[_c('div',{staticClass:"badge mr-8"},[_c('font-awesome-icon',{attrs:{"icon":"plus","transform":"shrink-6"}})],1),_c('span',[_vm._v("Create New \""+_vm._s(input)+"\" "+_vm._s(_vm.$t('global.distributor')))])])])]}},{key:"result",fn:function({ result }){return [_c('div',{staticClass:"venue-result"},[_c('img',{staticClass:"venue-result__image",attrs:{"src":result.logo,"alt":""}}),_c('div',{staticClass:"venue-result__info"},[_c('div',{staticClass:"venue-result__title"},[_c('span',{attrs:{"title":result.name}},[_vm._v(_vm._s(result.name))]),(_vm.$helpers.isPremium(result.accountType))?_c('ez-premium-badge'):_vm._e()],1)])])]}}],null,false,4084226119)}):_c('ez-input',{class:{
          'selected-supplier': true,
          'selected-supplier--disabled': _vm.invoice?.type === 'order',
          'selected-supplier--new': _vm.invoice?.order?.distributor?.isNew || _vm.isNewAddedSupplier,
        },attrs:{"disabled":"","disabledTooltip":_vm.invoice?.type === 'order'
            ? 'Supplier not editable because the invoice is uploaded for an existing order'
            : '',"formKey":"pending-invoice","value":_vm.invoice?.order?.distributor?.name,"name":"supplier","label":`${_vm.$t('global.distributor')}`},scopedSlots:_vm._u([(_vm.invoice?.order?.distributor?.isNew || _vm.isNewAddedSupplier)?{key:"prefix",fn:function(){return [_c('status-badge',{attrs:{"status":"new"}})]},proxy:true}:null,(!_vm.suggestedMatches.length && _vm.invoice?.type !== 'order')?{key:"suffix",fn:function(){return [_c('font-awesome-icon',{staticClass:"mr-8 ml-8",attrs:{"icon":"pen"},on:{"click":_vm.editSelectedSupplier}}),_c('font-awesome-icon',{staticClass:"mr-16",attrs:{"icon":"times"},on:{"click":_vm.removeSelectedSupplier}})]},proxy:true}:(_vm.invoice?.type !== 'order')?{key:"suffix",fn:function(){return [_c('div',{staticClass:"suffix-action",on:{"click":_vm.editSelectedSupplier}},[_c('font-awesome-icon',{directives:[{name:"tooltip",rawName:"v-tooltip",value:({
                placement: 'top',
                content: 'Potential matches',
                classes: ['tooltip--reset-margin', 'tooltip--lift-up'],
              }),expression:"{\n                placement: 'top',\n                content: 'Potential matches',\n                classes: ['tooltip--reset-margin', 'tooltip--lift-up'],\n              }"}],attrs:{"icon":"exclamation-circle"}}),_c('span',{staticClass:"ml-8"},[_vm._v("Potential matches")]),_c('font-awesome-icon',{directives:[{name:"tooltip",rawName:"v-tooltip",value:({
                placement: 'top',
                content: 'Edit',
                classes: ['tooltip--reset-margin', 'tooltip--lift-up'],
              }),expression:"{\n                placement: 'top',\n                content: 'Edit',\n                classes: ['tooltip--reset-margin', 'tooltip--lift-up'],\n              }"}],staticClass:"mr-8 ml-8",attrs:{"icon":"pen"}}),_c('font-awesome-icon',{directives:[{name:"tooltip",rawName:"v-tooltip",value:({
                placement: 'top',
                content: 'Clear',
                classes: ['tooltip--reset-margin', 'tooltip--lift-up'],
              }),expression:"{\n                placement: 'top',\n                content: 'Clear',\n                classes: ['tooltip--reset-margin', 'tooltip--lift-up'],\n              }"}],staticClass:"mr-16",attrs:{"icon":"times"},on:{"click":_vm.removeSelectedSupplier}})],1)]},proxy:true}:null],null,true)})],1),_c('ez-input',{ref:"invoiceNumberInput",staticClass:"ml-16",attrs:{"name":"invoiceNumber","label":"Invoice Number","formKey":"pending-invoice","value":_vm.invoice?.order?.invoiceNumber ?? null,"errorMsg":_vm.invoice?.validationErrors?.invoiceNumber?.[0] ?? '',"placeholder":"Enter Invoice Number"},on:{"onChange":_vm.onInvoiceNumberChange}}),_c('ez-date-input',{class:['mask-input', 'ml-16', { 'empty-date': !_vm.invoice?.order?.orderedAt }],attrs:{"form-key":"pending-invoice","name":"orderedAt","label":"Invoice Date","placeholder":_vm.invoice?.dateFormat || 'DD/MM/YYYY',"date-format":_vm.invoice?.dateFormat || 'DD/MM/YYYY',"value":_vm.dayjs(_vm.invoice.order.orderedAt)},on:{"onChange":_vm.updateDate}}),_c('ez-input',{staticClass:"ml-16",attrs:{"placeholder":"Tax Rate","formKey":"pending-invoice","type":"number","name":"tax","step":"0.01","label":"Tax Rate","value":_vm.invoice?.order?.tax !== undefined && _vm.invoice?.order?.tax !== null
          ? _vm.invoice?.order?.tax
          : _vm.invoice?.order?.distributor?.tax || 0,"disabled":!_vm.invoice?.order?.distributor?.id,"disabledTooltip":"Please select a supplier first.","min":"0"},on:{"onChange":_vm.onTaxChange},scopedSlots:_vm._u([{key:"suffix",fn:function(){return [_vm._v("%")]},proxy:true}])})],1),(_vm.editSupplierMode)?_c('div',{staticClass:"edit-box"},[_c('h2',{staticClass:"title"},[_vm._v("Edit Supplier")]),_c('p',[_vm._v("Edit this invoice’s supplier or select another match.")]),_c('ez-input',{ref:"supplierName",attrs:{"disabled":!(_vm.invoice?.order?.distributor?.isNew || _vm.isNewAddedSupplier) ||
        !_vm.invoice?.order?.distributor?.id,"formKey":"edit-supplier","value":_vm.invoice?.order?.distributor?.name,"name":"name","label":`${_vm.$t('global.distributor')}`},on:{"onChange":_vm.setUpdatedSupplierName}}),_c('div',{staticClass:"suggested-matches"},[(_vm.suggestedMatches && _vm.suggestedMatches.length)?_c('h2',{staticClass:"title"},[_vm._v("Suggested Matches")]):_vm._e(),(_vm.suggestedMatches && _vm.suggestedMatches.length)?_c('ez-table',{attrs:{"data":_vm.suggestedMatches,"columns":['name', 'action'],"headers":{
          name: () => 'Supplier',
          action: () => '',
        },"columnProps":{
          action: { class: 'medium-cell' },
        }},scopedSlots:_vm._u([{key:"cell-name",fn:function({ row }){return [_c('v-distributor-entity-info',{attrs:{"distributor":row || {}}})]}},{key:"cell-action",fn:function({ row }){return [_c('ez-button',{attrs:{"type":"primary"},on:{"click":function($event){return _vm.onDistributorSelect({ selected: row })}}},[_vm._v(" Switch "),_c('font-awesome-icon',{staticClass:"ml-4 mr-0",attrs:{"icon":"sync"}})],1)]}}],null,false,1145137217)}):_vm._e(),_c('hr'),_c('ez-autocomplete',{ref:"autocomplete",attrs:{"placeholder":"Search for a supplier","source":`/admin/search/distributors`,"hasConnectedParam":false,"requestParams":{
          venueId: _vm.invoice?.venue?.id,
          sameCurrency: 'true',
          fromGroup: true,
          includeTemp: true,
        },"searchProperty":"name","label":"Alternatively, search for a Supplier"},on:{"selected":_vm.onDistributorSelect},scopedSlots:_vm._u([{key:"icon",fn:function(){return [_c('font-awesome-icon',{attrs:{"icon":"search"}})]},proxy:true},{key:"result",fn:function({ result }){return [_c('div',{staticClass:"venue-result"},[_c('img',{staticClass:"venue-result__image",attrs:{"src":result.logo,"alt":""}}),_c('div',{staticClass:"venue-result__info"},[_c('div',{staticClass:"venue-result__title"},[_c('span',{attrs:{"title":result.name}},[_vm._v(_vm._s(result.name))]),(_vm.$helpers.isPremium(result.accountType))?_c('ez-premium-badge'):_vm._e()],1)])])]}}],null,false,3386124414)})],1),_c('hr'),_c('footer',{staticClass:"edit-footer mt-24"},[_c('ez-button',{staticClass:"mr-auto",attrs:{"type":"link"},on:{"click":_vm.cancelEdit}},[_vm._v("Cancel")]),_c('ez-button',{on:{"click":_vm.updateSupplierName}},[_vm._v(" Save Changes ")])],1)],1):_vm._e(),(_vm.supplierAlternative.name)?_c('ez-confirmation-modal',{ref:"confirmSwitchModal",staticClass:"confirm-modal",scopedSlots:_vm._u([{key:"title",fn:function(){return [_vm._v("Confirm Supplier Switch?")]},proxy:true},{key:"content",fn:function(){return [_vm._v(" If you confirm, the current supplier will be switched to "),_c('br'),_c('p',[_c('strong',{staticClass:"mt-16"},[_vm._v(_vm._s(_vm.supplierAlternative.name))])])]},proxy:true},{key:"footer",fn:function(){return [_c('ez-button',{attrs:{"type":"link"},on:{"click":_vm.close}},[_vm._v("Cancel")]),_c('ez-button',{attrs:{"type":"primary"},on:{"click":_vm.confirmSwitch}},[_vm._v("Confirm")])]},proxy:true}],null,false,3890250459)}):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }