<template>
  <ez-wizard fullWidth @exit="onWizardExit">
    <template #title>
      <span>{{ title }}</span>
      <v-badge v-if="type" class="title-badge ml-8">{{ type }}</v-badge>
    </template>
    <div class="merge-view">
      <ez-loader :show="isLoading">Loading...</ez-loader>
      <div v-if="selectedFile.image" class="selected-file">
        <div class="selected-file__container">
          <VPdfViewer
            v-if="selectedFile && selectedFile.type === 'pdf'"
            :src="selectedFile.image"
            :show-all-pages="false"
            :controls="true"
            :name="selectedFile.name"
            :id="selectedFile.id"
          />
          <v-image-viewer v-else :file="selectedFile" :controls="true" />
        </div>
        <div class="actions">
          <ez-button
            :type="fileChecked(selectedFile.id) ? 'primary' : 'secondary'"
            @click="selectFile(selectedFile)"
          >
            <font-awesome-icon v-if="fileChecked(selectedFile.id)" icon="check" />
            {{ fileChecked(selectedFile.id) ? 'Selected' : 'Select' }}
          </ez-button>
          <ez-input
            class="ml-16"
            label="Page Number"
            type="number"
            :value="getPageNumber(selectedFile) || null"
            formKey="merge-invoice"
            name="pageNumber"
            :minValue="1"
            @onChange="updatePageNumber(selectedFile, $event)"
          />
        </div>
      </div>
      <div>
        <div class="processing-actions">
          <ez-button
            type="secondary"
            :class="{ selected: note === 'missing_page' }"
            @click="selectNote('missing_page')"
          >
            Missing Page
          </ez-button>
          <ez-button
            type="secondary"
            :class="{ selected: note === 'blurry_image' }"
            @click="selectNote('blurry_image')"
          >
            Blur Invoice
          </ez-button>
          <ez-button
            type="secondary"
            :class="{ selected: note === 'not_valid' }"
            @click="selectNote('not_valid')"
          >
            Not Valid
          </ez-button>
          <ez-button
            type="secondary"
            :class="{ selected: note === 'missing_info' }"
            @click="selectNote('missing_info')"
          >
            Info Missing
          </ez-button>
          <div class="dropdown-button">
            <ez-button @click="mergeInvoices" :disabled="isCompleting">Merge & Next</ez-button>
            <ez-button-dropdown buttonType="primary" :disabled="isCompleting">
              <template #icon>
                <font-awesome-icon icon="angle-down" />
              </template>
              <template #dropdown>
                <ez-button @click="mergeAndReturnToQueue" type="secondary"
                  >Merge and return to Queue</ez-button
                >
                <ez-button @click="mergeAndReturnToQueue(false)" type="secondary"
                  >Cancel and return to Queue</ez-button
                >
              </template>
            </ez-button-dropdown>
          </div>
        </div>
        <div class="files-wrapper">
          <div
            :class="['file', { 'file--selected': fileChecked(file.id) }]"
            v-for="file in invoiceFiles"
            :key="file.id"
          >
            <img class="checkmark" v-if="fileChecked(file.id)" src="@/assets/lite-badge.svg" />
            <div class="file-details" @click="selectFile(file)">
              <p>{{ file.pendingInvoice.distributor?.name || 'Missing distributor name' }}</p>
              <p>
                Invoice No. <span>{{ file.pendingInvoice.invoiceNumber || '-' }}</span>
              </p>
              <p>
                Invoice Date <span>{{ formatDate(file.pendingInvoice.invoiceDate) || '-' }}</span>
              </p>
            </div>
            <div class="file-content">
              <VPdfViewer
                v-if="file.type === 'pdf'"
                :src="file.image"
                :show-all-pages="false"
                @click="selectFile(file)"
              />
              <img v-else :src="file.image" @click="selectFile(file)" />
              <ez-input
                class="mt-12"
                label="Page Number"
                type="number"
                :value="getPageNumber(file) || null"
                formKey="merge-invoice"
                name="pageNumber"
                :minValue="1"
                @onChange="updatePageNumber(file, $event)"
              />
            </div>
          </div>
        </div>
        <div v-if="isLoadingMore" class="u-text-center mt-12">
          <ez-spinner />
        </div>
        <ez-load-more @loadMore="onLoadMore" v-if="meta.page < meta.lastPage && !isLoadingMore" />
      </div>
    </div>
  </ez-wizard>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import { formatDate } from '@/util/utils';
import { LOADING_KEY } from '@/util/constants';
import flash from '@/components/ui/FlashMessage';
import EzWizard from '@/components/layout/Wizard.vue';
import VPdfViewer from '@/components/v3/elements/VPdfViewer';
import VImageViewer from '@/components/v3/elements/VImageViewer';
import EzButton from '@/components/ui/Button/EzButton.vue';
import EzLoadMore from '@/components/ui/LoadMore';
import EzInput from '@/components/ui/Input/EzInput.vue';
import EzLoader from '@/components/ui/Loader/EzLoader';
import EzSpinner from '@/components/ui/Spinner/EzSpinner.vue';
import httpService from '@/api/http';
import EzButtonDropdown from '@/components/ui/ButtonDropdown/EzButtonDropdown.vue';
import VBadge from '@/components/v3/elements/VBadge';

export default {
  components: {
    EzWizard,
    VPdfViewer,
    VImageViewer,
    EzButton,
    EzLoadMore,
    EzInput,
    EzLoader,
    EzSpinner,
    EzButtonDropdown,
    VBadge,
  },
  props: {
    invoiceId: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      invoiceFiles: [],
      meta: {},
      formatDate,
      selectedFiles: [],
      filesWithPages: [],
      selectedFile: { image: null },
      note: null,
      venue: {},
      group: {},
      title: '',
      type: null,
    };
  },
  computed: {
    ...mapGetters('loading', ['getLoading']),
    isLoading() {
      return this.getLoading(LOADING_KEY.FETCH_ADMIN_PENDING_INVOICE_MERGE);
    },
    isLoadingMore() {
      return this.getLoading(LOADING_KEY.FETCH_ADMIN_PENDING_INVOICE_MERGE_LOAD_MORE);
    },
    isCompleting() {
      return this.getLoading(LOADING_KEY.ADMIN_PENDING_INVOICE_MERGE);
    },
  },
  methods: {
    ...mapActions('entities/orders', [
      'adminFetchPendingInvoiceMerge',
      'adminPendingInvoiceMerge',
      'adminFetchNextPendingInvoice',
      'adminReleasePendingInvoice',
    ]),
    onWizardExit() {
      this.$router.push({ name: 'admin-clearing-app-processing' });
    },
    async onLoadMore() {
      const { data } = await this.adminFetchPendingInvoiceMerge({
        invoiceId: this.invoiceId,
        page: this.meta.page + 1,
        loadingKey: LOADING_KEY.FETCH_ADMIN_PENDING_INVOICE_MERGE_LOAD_MORE,
      });
      this.invoiceFiles = [...this.invoiceFiles, ...data.data];
      this.meta = data.meta;
    },
    selectNote(note) {
      if (this.note === note) this.note = null;
      else this.note = note;
    },
    selectFile(file) {
      const allFiles = this.invoiceFiles.filter(
        invoice => invoice.pendingInvoice.id === file.pendingInvoice.id,
      );
      if (this.selectedFiles.includes(file.id) && file.pendingInvoice.id !== this.invoiceId) {
        allFiles.forEach(f => {
          this.selectedFiles = this.selectedFiles.filter(item => item !== f.id);
        });
        [this.selectedFile] = this.invoiceFiles;
      } else if (file.pendingInvoice.id !== this.invoiceId) {
        allFiles.forEach(f => {
          this.selectedFiles.push(f.id);
        });
        this.selectedFile = file;
      } else {
        this.selectedFile = file;
      }
    },
    fileChecked(id) {
      return this.selectedFiles.includes(id);
    },
    updatePageNumber(file, event) {
      const index = this.filesWithPages.findIndex(item => item.id === file.id);
      if (index === -1) {
        this.filesWithPages.push({
          id: file.id,
          pageNumber: event,
        });
        if (!this.fileChecked(file.id)) this.selectFile(file);
      } else {
        this.filesWithPages[index].pageNumber = event;
      }
    },
    getPageNumber(file) {
      const index = this.filesWithPages.findIndex(item => item.id === file.id);
      if (index === -1) {
        return null;
      }
      return this.filesWithPages[index].pageNumber;
    },
    async openSinglePendingInvoice({ id }) {
      await httpService.put(`admin/pending-invoices/${id}/user`);
      this.$router.push({
        name: 'admin-clearing-app-processing-single',
        params: {
          id,
        },
        query: {
          status: null,
        },
      });
    },
    async mergeInvoices(merge = true) {
      const body = {
        invoices: [],
        ...(this.note ? { note: this.note } : {}),
      };
      this.selectedFiles.forEach(file => {
        body.invoices.push({
          pendingInvoiceId: this.invoiceFiles.filter(item => item.id === file)[0].pendingInvoice.id,
          id: file,
          page: +this.getPageNumber({ id: file }),
        });
      });
      try {
        if (merge) {
          await this.adminPendingInvoiceMerge({
            invoiceId: this.invoiceId,
            body,
          });
          flash.success({ title: 'Pending invoice files successfully merged' });
          if (this.$permission.has('adminReviewInvoices') && this.note !== 'missing_page') {
            this.$router.push({
              name: 'admin-clearing-app-processing-single',
              params: {
                id: this.invoiceId,
              },
            });
            return;
          }
        } else {
          this.adminReleasePendingInvoice({ invoiceId: this.invoiceId });
        }
        let filters = {};
        if (localStorage.getItem('processingFilters')) {
          filters = JSON.parse(localStorage.getItem('processingFilters'));
        }
        const { data } = await this.adminFetchNextPendingInvoice(filters);
        if (data.data?.id) {
          await this.openSinglePendingInvoice({
            id: data.data.id,
            venue: data.data.venue,
            noteKey: data.data.noteKey,
          });
        } else {
          this.$router.push({ name: 'admin-clearing-app-processing' });
        }
      } catch (e) {
        flash.error({
          title: 'Something went wrong!',
          message: e.response.data?.error?.message ?? '',
        });
      }
    },
    async mergeAndReturnToQueue(merge = true) {
      try {
        const body = {
          invoices: [],
          ...(this.note ? { note: this.note } : {}),
        };
        this.selectedFiles.forEach(file => {
          body.invoices.push({
            pendingInvoiceId: this.invoiceFiles.filter(item => item.id === file)[0].pendingInvoice
              .id,
            id: file,
            page: +this.getPageNumber({ id: file }),
          });
        });
        if (merge) {
          await this.adminPendingInvoiceMerge({
            invoiceId: this.invoiceId,
            body,
          });
          flash.success({ title: 'Pending invoice files successfully merged' });
        } else {
          this.adminReleasePendingInvoice({ invoiceId: this.invoiceId });
        }
        this.$router.push({ name: 'admin-clearing-app-processing' });
      } catch (e) {
        flash.error({
          title: 'Something went wrong!',
          message: e.response.data?.error?.message ?? '',
        });
      }
    },
  },
  async beforeMount() {
    const { data } = await this.adminFetchPendingInvoiceMerge({
      invoiceId: this.invoiceId,
      page: 1,
    });
    this.invoiceFiles = data.data;
    this.meta = data.meta;
    [this.selectedFile] = this.invoiceFiles;
    const allFiles = this.invoiceFiles.filter(
      invoice => invoice.pendingInvoice.id === this.invoiceId,
    );
    this.venue = this.invoiceFiles.filter(
      invoice => invoice.pendingInvoice.id === this.invoiceId,
    )[0].pendingInvoice.venue;
    this.group = this.invoiceFiles.filter(
      invoice => invoice.pendingInvoice.id === this.invoiceId,
    )[0].pendingInvoice.group;
    const { type } = this.invoiceFiles.filter(
      invoice => invoice.pendingInvoice.id === this.invoiceId,
    )[0].pendingInvoice;
    if (type === 'invoice') this.type = 'Invoice';
    else if (type === 'order') this.type = 'Order';
    else if (type === 'credit_note') this.type = 'Credit Note';
    allFiles.forEach(f => {
      this.selectedFiles.push(f.id);
      this.filesWithPages.push({
        id: f.id,
        pageNumber: f.index + 1,
      });
    });
    this.title =
      `${this.group?.name} ${this.venue?.name ? `- ${this.venue?.name}` : ''} ${
        this.venue?.legalName ? `(${this.venue?.legalName})` : ''
      }` || '';
  },
};
</script>

<style lang="scss" scoped>
:deep() .ez-wizard__main {
  max-width: none;
  padding: 32px 60px;

  .ez-wizard__page-header {
    margin: 0;
  }
}

:deep() .title-badge.e-badge {
  display: inline;
}

.merge-view {
  display: flex;
  justify-content: center;
  align-items: flex-start;
}

.selected-file {
  position: sticky;
  top: 80px;
  flex: 1 1 100%;
  max-width: 420px;
  margin-right: 48px;

  &__container {
    width: 100%;
    display: inline-block;
    height: 560px;
    overflow-x: auto;
    overflow-y: auto;
    border: 1px solid #e1e5ed;
    border-radius: 3px;
    background-color: #e9ebf2;
  }

  :deep() .pdf-container {
    border: none;
    border-radius: 0;
    background-color: transparent;
  }

  .actions {
    margin-top: 40px;
    display: flex;
    align-items: flex-end;
    justify-content: center;
  }
}

.processing-actions {
  display: flex;
  justify-content: flex-end;
  margin-bottom: 32px;
  flex-wrap: wrap;
  row-gap: 12px;

  :deep() .button {
    min-width: 146px;
    & + .button {
      margin-left: 32px;
    }
    display: flex;
    align-items: center;
    justify-content: center;

    &.button--secondary {
      border: 2px solid $color-gray-F5;
      &.selected {
        border: 2px solid $color-primary-blue;
      }
    }
  }
}

.files-wrapper {
  max-width: 860px;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  grid-column-gap: 16px;
  grid-row-gap: 16px;

  .file {
    position: relative;
    display: flex;
    flex-direction: column;
    max-width: 200px;
    border-radius: 5px;
    border: 2px solid #eceef5;
    p {
      margin: 0;
      font-size: 14px;
      line-height: 1.5;
      color: $color-gray-25;
      & + p {
        margin-top: 4px;
      }
      span {
        color: $color-gray-6C;
      }
    }
    &--selected {
      border: 2px solid $color-primary-blue;
    }
    &-details {
      padding: 16px;
      padding-right: 24px;
      cursor: pointer;
    }
    &-content {
      display: flex;
      flex-direction: column;
      flex: 1;
      justify-content: space-between;
      align-items: center;
      padding: 30px 30px 20px 30px;
      background-color: $color-gray-F5;

      :deep() .button {
        margin-top: 20px;
      }
    }
    .checkmark {
      position: absolute;
      top: 8px;
      right: 8px;
      width: 18px;
      height: 18px;
    }

    :deep() .pdf-page-canvas,
    img {
      width: 100%;
      cursor: pointer;
    }
  }
}

.dropdown-button {
  :deep() .button.button--primary {
    padding-right: 6px;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
    min-width: initial;
    display: inline-block;
    margin-left: 32px;
  }
  :deep() .button-dropdown.button-dropdown--primary {
    padding-left: 0px;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;

    .button.button--primary {
      padding-left: 6px;
      padding-right: 12px;
      border-top-left-radius: 0;
      border-bottom-left-radius: 0;
      border-top-right-radius: 3px;
      border-bottom-right-radius: 3px;
      margin-left: 0;
    }
    .button.button--secondary {
      min-width: initial;
      margin-left: 0;
      justify-content: flex-start;
      border: none;
      background-color: #fff;

      &:hover {
        background-color: #f5f6fa;
      }
    }
  }
}
</style>
