<script>
import { falsy, debounce } from '@/util/utils';
import EzMaskInputSimple from '@/components/ui/MaskInputSimple/EzMaskInputSimple.vue';
import EzButton from '@/components/ui/Button';
import { UNIT_TYPE_FRACTIONAL } from '@/util/constants';
import { getTaxValue, roundNumber } from '@/util/utilsFinCalculator';
import httpService from '@/api/http';
import flash from '@/components/ui/FlashMessage';
import StatusBadge from '@/views/common/status-badge/StatusBadge.vue';
import { mapActions } from 'vuex';
import EzTable from '@/components/ui/Table/EzTable';
import EzEntityInfo from '@/components/ui/EntityInfo/EzEntityInfo';
import VDataWithInfo from '@/components/v3/elements/VDataWithInfo';
import EzAutocomplete from '@/components/ui/Autocomplete/EzAutocomplete.vue';
import EzConfirmationModal from '@/components/ui/Modal/EzConfirmationModal.vue';
import ProductForm from './ProductForm.vue';

export default {
  components: {
    EzMaskInputSimple,
    EzButton,
    ProductForm,
    StatusBadge,
    EzTable,
    EzEntityInfo,
    VDataWithInfo,
    EzAutocomplete,
    EzConfirmationModal,
  },
  props: {
    /**
     * @type {Invoice}
     */
    invoice: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      ORDER_UPDATE_ENUM: {
        QUANTITY: 1,
        PRICE: 2,
        TAX: 3,
        TOTAL_PRICE: 4,
        TOTAL_PRICE_NO_TAX: 5,
      },
      activeProduct: {}, // Product that is being edited
      lockChanges: false,
      suggestedMatches: [],
      productAlternative: {},
    };
  },
  computed: {
    isProductEditMode() {
      return !falsy(this.activeProduct);
    },
    hasDistributor() {
      return !!this.invoice?.order?.distributor?.id;
    },
  },
  methods: {
    /**
     * Update a the order with the given id
     *
     * @param {ORDER_UPDATE_ENUM} type
     * @param {number} id
     * @param {number|string} value
     */
    ...mapActions('entities/products', ['fetchProductsSuggestedMatches']),
    updateProduct: debounce(function deb(type, product, value) {
      if (!this.lockChanges) {
        if (!product) return;
        if (type === this.ORDER_UPDATE_ENUM.QUANTITY) product.quantity = value;
        if (type === this.ORDER_UPDATE_ENUM.TAX) product.tax = value;
        if (type === this.ORDER_UPDATE_ENUM.PRICE) {
          if (roundNumber({ number: product.price || 0, decimals: 4 }) !== value)
            product.price = value;
          else return;
        }
        if (type === this.ORDER_UPDATE_ENUM.TOTAL_PRICE) {
          if (roundNumber({ number: product.totalAmount || 0, decimals: 4 }) !== value)
            product.totalAmount = value;
          else return;
        }
        if (type === this.ORDER_UPDATE_ENUM.TOTAL_PRICE_NO_TAX) {
          if (roundNumber({ number: product.totalPrice || 0, decimals: 4 }) !== value)
            product.totalPrice = value;
          else return;
        }

        this.updatePrice(product, type);
      }
    }, 50),
    updatePrice(product, type) {
      this.lockChanges = true;
      if (
        type !== this.ORDER_UPDATE_ENUM.TOTAL_PRICE &&
        type !== this.ORDER_UPDATE_ENUM.TOTAL_PRICE_NO_TAX
      ) {
        const totalPriceWithoutTax = product.price * product.quantity;

        if (
          this.invoice?.order?.taxCalculation === 'inclusive' ||
          this.invoice?.order?.taxCalculation === 'exempt'
        ) {
          product.totalAmount = totalPriceWithoutTax;
          product.totalPrice = totalPriceWithoutTax;
          this.lockChanges = false;
          return;
        }

        product.totalAmount =
          totalPriceWithoutTax +
          getTaxValue({
            price: totalPriceWithoutTax,
            tax: product.tax,
            taxCalculation: this.invoice?.order?.taxCalculation || 'exclusive',
            decimals: 2,
          });
        product.totalPrice = totalPriceWithoutTax;
      } else if (type === this.ORDER_UPDATE_ENUM.TOTAL_PRICE_NO_TAX) {
        const newPrice = product.totalPrice / product.quantity;
        if (
          roundNumber({ number: newPrice, decimals: 2 }) !==
          roundNumber({ number: product.price, decimals: 2 })
        ) {
          product.price = newPrice;
        }
        if (
          this.invoice?.order?.taxCalculation === 'exempt' ||
          this.invoice?.order?.taxCalculation === 'inclusive'
        ) {
          product.totalAmount = product.totalPrice;
          this.lockChanges = false;
          return;
        }
        const totalWithTax = product.totalPrice * (1 + product.tax / 100);
        product.totalAmount = totalWithTax;
      } else {
        if (
          this.invoice?.order?.taxCalculation === 'exempt' ||
          this.invoice?.order?.taxCalculation === 'inclusive'
        ) {
          const newPrice = product.totalAmount / product.quantity;
          if (
            roundNumber({ number: newPrice, decimals: 2 }) !==
            roundNumber({ number: product.price, decimals: 2 })
          ) {
            product.price = newPrice;
          }
          product.totalPrice = product.totalAmount;
          this.lockChanges = false;
          return;
        }
        const totalWithoutTax = product.totalAmount / (1 + product.tax / 100);
        product.totalPrice = totalWithoutTax;
        const newPrice = totalWithoutTax / product.quantity;
        if (
          roundNumber({ number: newPrice, decimals: 2 }) !==
          roundNumber({ number: product.price, decimals: 2 })
        ) {
          product.price = newPrice;
        }
      }
      this.lockChanges = false;
    },
    removeProduct(product) {
      this.invoice.order.products = this.invoice.order.products.filter(
        p => p.internalId !== product.internalId,
      );
      this.activeProduct = {};
    },
    async openEditProduct(product) {
      if (this.invoice.order.distributor && this.invoice.order.distributor.id) {
        const { data } = await this.fetchProductsSuggestedMatches({
          distributorId: this.invoice.order.distributor.id,
          venueId: this.invoice.venue.id,
          term: product.name,
          productId: product.id,
        });
        this.suggestedMatches = data.data;
      }
      this.activeProduct = { ...product, isDirty: false };
    },
    async onProductSelect(prod) {
      this.productAlternative = prod.selected;
      await this.$nextTick();
      this.$refs.confirmSwitchModal.open();
      await this.$nextTick();
      const confirmButton = document.querySelector(
        '.confirm-modal .modal__footer .button--primary',
      );
      if (confirmButton) confirmButton.focus();
    },
    confirmSwitch() {
      const index = this.invoice.order.products.findIndex(
        item => item.internalId === this.activeProduct.internalId,
      );
      this.invoice.order.products[index] = {
        ...this.productAlternative,
        internalId: this.invoice.order.products[index].internalId,
        isNew: false,
        price: this.invoice.order.products[index].price,
        orderingUnit: this.invoice.order.products[index].orderingUnit,
        priceQuantity: this.invoice.order.products[index].priceQuantity,
        tax: this.invoice.order.products[index].tax,
        totalAmount: this.invoice.order.products[index].totalAmount,
        totalPrice: this.invoice.order.products[index].totalPrice,
        quantity: this.invoice.order.products[index].quantity,
      };
      this.$refs.confirmSwitchModal.close();
      this.activeProduct = {};
      this.productAlternative = {};
    },
    close() {
      this.$refs.confirmSwitchModal.close();
    },
    restProductEdit() {
      this.activeProduct = {};
    },
    async saveProductEdit() {
      try {
        await httpService.patch(
          `/admin/distributors/${this.invoice?.order?.distributor?.id}/products/${
            this.activeProduct.productId || this.activeProduct.id
          }`,
          {
            name: this.activeProduct.name,
            sku: this.activeProduct.sku,
            orderingUnitId: this.activeProduct.orderingUnit.id,
            priceUnitId: this.activeProduct.orderingUnit.id,
          },
        );

        this.invoice.order.products.forEach(product => {
          if (
            (product.productId ? product.productId : product.id) ===
            (this.activeProduct.productId ? this.activeProduct.productId : this.activeProduct.id)
          ) {
            product.sku = this.activeProduct.sku;
            product.name = this.activeProduct.name;
            product.orderingUnit = { ...this.activeProduct.orderingUnit };
          }
        });
        flash.success({ title: 'Product successfully edited' });
        this.activeProduct = {};
      } catch (e) {
        flash.error({
          title: 'Something went wrong!',
          message: e.response?.data?.error?.message || '',
        });
      }
    },
    isFractional(orderingUnit) {
      return orderingUnit?.type === UNIT_TYPE_FRACTIONAL;
    },
  },
  watch: {
    // Update price for each product if tax calulcation changes
    'invoice.order.taxCalculation': {
      handler() {
        this.invoice.order.products.forEach(p => this.updatePrice(p));
      },
    },
  },
};
</script>
<template>
  <div>
    <div v-if="!isProductEditMode && invoice?.order?.products?.length" class="product-label mt-24">
      Product
    </div>
    <div v-if="!isProductEditMode && invoice?.order?.products?.length" class="product-grid">
      <div
        class="product-grid__wrapper"
        v-for="product in invoice?.order?.products ?? []"
        :key="product.internalId"
      >
        <!-- <div class="product-grid__header"> -->
        <div v-if="!product.isNew" class="product-grid__name">{{ product.name }}</div>
        <div v-else class="product-grid__name product-grid__name--new">
          <status-badge status="new" class="mr-16" />
          {{ product.name }}
        </div>
        <div v-if="!product.isNew" class="product-grid__info product-grid__cell--right">
          <span class="dark-color">{{ product.orderingUnit.name }}</span>
          <span v-if="product.sku" class="mx-4">&#8226;</span>
          <span v-if="product.sku" class="light-gray">{{ product.sku }}</span>

          <div class="actions">
            <ez-button type="link" @click.stop="openEditProduct(product)" class="action">
              <font-awesome-icon icon="pen" />
            </ez-button>
            <ez-button type="link" @click.stop="removeProduct(product)" class="action">
              <font-awesome-icon icon="trash" />
            </ez-button>
          </div>
        </div>
        <div v-else class="product-grid__info product-grid__cell--right product-grid__info--new">
          <ez-button type="primary" @click.stop="openEditProduct(product)" class="action">
            <span class="mr-8">Edit</span>
            <font-awesome-icon icon="pen" class="mr-0" />
          </ez-button>
        </div>
        <div class="product-grid__header">Quantity</div>
        <div class="product-grid__header product-grid__cell--right">Price per Unit</div>
        <div class="product-grid__header product-grid__cell--right">Line Amount</div>
        <div class="product-grid__header product-grid__cell--right">Tax</div>
        <div class="product-grid__header product-grid__cell--right">Line Amount w/ Tax</div>
        <div class="product-grid__cell">
          <ez-mask-input-simple
            name="quantity"
            :minValue="0"
            :value="product.quantity"
            @input="updateProduct(ORDER_UPDATE_ENUM.QUANTITY, product, $event)"
            class="discount__input quantity__input"
            :precision="isFractional(product.orderingUnit) ? 4 : 0"
          />
        </div>
        <div class="product-grid__cell product-grid__cell--right">
          <ez-mask-input-simple
            :currency="product.currency || invoice.group.currency"
            :value="product.price || 0"
            @input="updateProduct(ORDER_UPDATE_ENUM.PRICE, product, $event)"
            class="discount__input price__input"
            :precision="4"
          />
        </div>
        <div class="product-grid__cell product-grid__cell--right">
          <ez-mask-input-simple
            :currency="product.currency || invoice.group.currency"
            :value="product.totalPrice || 0"
            @input="updateProduct(ORDER_UPDATE_ENUM.TOTAL_PRICE_NO_TAX, product, $event)"
            class="discount__input price__input"
            :precision="4"
          />
        </div>
        <div class="product-grid__cell product-grid__cell--right">
          <ez-mask-input-simple
            customPrefix="%"
            :value="product.tax || 0"
            :precision="2"
            :minValue="0"
            :maxValue="100"
            :isInvalid="false"
            @input="updateProduct(ORDER_UPDATE_ENUM.TAX, product, $event)"
            class="discount__input"
          />
        </div>
        <div class="product-grid__cell product-grid__cell--right">
          <ez-mask-input-simple
            :currency="product.currency || invoice.group.currency"
            :value="product.totalAmount || 0"
            @input="updateProduct(ORDER_UPDATE_ENUM.TOTAL_PRICE, product, $event)"
            class="discount__input price__input"
            :precision="4"
          />
        </div>
      </div>
    </div>
    <div v-else-if="isProductEditMode" class="edit-box">
      <h2 class="title">Edit Product</h2>
      <p>Edit this product’s details or select another match.</p>
      <product-form
        v-if="activeProduct.isNew"
        :product="activeProduct"
        @unitChange="updateProduct(ORDER_UPDATE_ENUM.UNIT, product, $event)"
        :venue-id="this.invoice.venue.id"
        :invoice-id="this.invoice.id"
      />
      <ez-entity-info v-else imgWidth="2rem" imgHeight="2rem" :imgUrl="activeProduct.image">
        <div class="product-info" :title="activeProduct.name">
          <div class="cell-product-container">
            <span>{{ activeProduct.name }}</span>
            <span class="product-info-secondary"
              >{{ activeProduct.orderingUnit.label }}{{ activeProduct.sku ? ' &#8226; ' : ''
              }}{{ activeProduct.sku }}</span
            >
          </div>
        </div>
      </ez-entity-info>
      <hr v-if="suggestedMatches && suggestedMatches.length" />
      <div class="suggested-matches">
        <h2 class="title" v-if="suggestedMatches && suggestedMatches.length">Suggested Matches</h2>
        <ez-table
          v-if="suggestedMatches && suggestedMatches.length"
          :data="suggestedMatches"
          :columns="['product', 'unit', 'action']"
          :headers="{
            product: () => 'Product',
            action: () => '',
          }"
          :columnProps="{
            unit: { class: 'medium-cell unit-cell' },
            action: { class: 'medium-cell' },
          }"
        >
          <template #cell-product="{ row }">
            <div class="cell-product-container">
              <ez-entity-info imgWidth="2rem" imgHeight="2rem" :imgUrl="row.image">
                <div class="product-info" :title="row.name">
                  <span>{{ row.name }}</span>
                  <span class="product-info-secondary">
                    {{ row.category | categoryWithParent }}{{ row.sku ? ' &#8226; ' : ''
                    }}{{ row.sku }}
                  </span>
                </div>
              </ez-entity-info>
            </div>
          </template>
          <template #cell-unit="{ row: { orderingUnit } }">
            <v-data-with-info
              :info="
                orderingUnit.abbreviation
                  ? `${orderingUnit.name} ${
                      !!$t(`product.unitInfo.${orderingUnit.type}`) ? '-' : ''
                    }
              ${$t(`product.unitInfo.${orderingUnit.type}`)}`
                  : ''
              "
              :show-underline="
                !!$t(`product.unitInfo.${orderingUnit.type}`) || !!orderingUnit.abbreviation
              "
            >
              {{ orderingUnit.label }}
            </v-data-with-info>
          </template>
          <template #cell-action="{ row }">
            <ez-button type="primary" @click="onProductSelect({ selected: row })">
              Switch
              <font-awesome-icon icon="sync" class="ml-4 mr-0" />
            </ez-button>
          </template>
        </ez-table>
        <hr />
        <ez-autocomplete
          v-if="hasDistributor"
          ref="autocomplete"
          placeholder="Search for a product"
          :source="`admin/distributors/${invoice?.order?.distributor?.id}/search/products`"
          searchProperty="name"
          label="Alternatively, search for a Product"
          @selected="onProductSelect"
        >
          <template #icon>
            <font-awesome-icon icon="search" />
          </template>
          <template #result="{ result }">
            <div class="venue-result">
              <img :src="result.image" alt="" class="venue-result__image" />
              <div class="venue-result__info">
                <div class="venue-result__title">
                  <span :title="result.name">{{ result.name }}</span>
                </div>
                <div class="venue-result__label mt-4">
                  <span>{{ result.orderingUnit.label }}</span>
                  <span v-if="result.sku" class="mx-4">&#8226;</span>
                  <span>{{ result.sku }}</span>
                </div>
              </div>
            </div>
          </template>
        </ez-autocomplete>
      </div>

      <hr />
      <footer class="edit-footer mt-24">
        <ez-button @click="restProductEdit" type="link" class="mr-auto">Cancel</ez-button>
        <ez-button type="secondary" @click.stop="removeProduct(activeProduct)" class="mr-16">
          Remove<font-awesome-icon icon="trash" class="ml-8 mr-0" />
        </ez-button>
        <ez-button @click="saveProductEdit" :disabled="!activeProduct.isDirty">
          Save Changes
        </ez-button>
      </footer>
    </div>
    <ez-confirmation-modal
      class="confirm-modal"
      v-if="productAlternative.name"
      ref="confirmSwitchModal"
    >
      <template #title>Confirm Product Switch?</template>
      <template #content>
        If you confirm, the current product will be switched to
        <br />
        <p>
          <strong class="mt-16">{{ productAlternative.name }}</strong>
        </p>
      </template>
      <template #footer>
        <ez-button @click="close" type="link">Cancel</ez-button>
        <ez-button @click="confirmSwitch" type="primary">Confirm</ez-button>
      </template>
    </ez-confirmation-modal>
  </div>
</template>
<style lang="scss" scoped>
.title {
  font-weight: 500;
}
.product-label {
  font-size: 12px;
  line-height: 1.5;
  font-weight: 500;
  color: $color-gray-6C;
  padding-bottom: 8px;
  border-bottom: 2px solid #eceef5;
}
.product-grid {
  &__wrapper {
    display: grid;
    grid-template-rows: repeat(3, auto);
    grid-template-columns: repeat(5, 1fr);

    // grid-area: name name name info
    //   t_quantity t_price t_tax t_total
    //   c_quantity c_price c_tax c_total;

    .product-grid__info:hover {
      .actions {
        opacity: 1;
      }
    }
  }

  .actions {
    position: absolute;
    inset: 0;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    color: $color-gray-6C;
    background: #fff;
    opacity: 0;
    transition: opacity 0.3s ease-in-out;
  }

  &__name,
  &__info {
    padding: 10px 0;
    border-bottom: 1px dashed $color-gray-E1;
  }

  &__name {
    grid-column: 1 / span 3;
    font-size: 14px;
    line-height: 1.4;
    color: $color-gray-25;
    &--new {
      display: flex;
      align-items: center;
      padding: 10px;
      background-color: $color-pastel-blue;
      border-bottom: 1px solid #a3bbff;

      :deep() .status--new {
        background-color: #4d7cfe;
        color: white;
      }
    }
  }

  &__info {
    position: relative;
    font-size: 12px;
    line-height: 1.5;
    &--new {
      padding: 10px;
      background-color: $color-pastel-blue;
      border-bottom: 1px solid #a3bbff;
    }
    grid-column: 4 / span 2;
  }

  &__header {
    padding: 6px 0;
    font-size: 12px;
    line-height: 1.5;
    font-weight: 500;
    color: $color-gray-6C;
    border-bottom: 2px solid #eceef5;
  }

  &__cell {
    position: relative;
    display: flex;
    align-items: center;
    padding: 14px 0;
    border-bottom: 2px solid $color-primary-blue;

    &--right {
      text-align: right;
      justify-content: flex-end;
    }

    :deep() .ez-number-input,
    :deep() .mask-input-wrapper {
      display: inline-block; // Make it text alignable
      width: auto; // Reset inherited 100%
      max-width: 120px; // Limit
    }

    :deep() .quantity__input input {
      text-align: center;
    }

    @media (min-width: 1440px) {
      :deep() .price__input.mask-input-wrapper .mask-input {
        width: 120px;
      }
    }
  }

  .light-gray {
    color: $color-gray-6C;
  }

  .dark-color {
    color: $color-gray-25;
  }
}
.edit-footer {
  display: flex;

  .mr-auto {
    margin-right: auto;
  }
}
.product-info {
  display: flex;
  flex-direction: column;
  span {
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 100%;
  }
}

.product-info-secondary {
  display: block;
}

.suggested-matches {
  :deep() .button.button--primary {
    background-color: #eedff4;
    color: $color-primary-purple;
  }

  .venue-result {
    @extend %flex-center;

    &__image {
      @include size(32px);
      border-radius: 3px;
      border: 1px solid #dee1e4;
    }

    &__info {
      margin-left: 8px;
    }

    &__titele {
      color: #252631;
      font-size: 14px;
      font-weight: 500;
      line-height: 16px;
    }

    &__label {
      color: #6c7995;
      font-size: 12px;
      font-weight: 500;
      letter-spacing: 0;
      line-height: 14px;
    }
  }

  :deep() .autocomplete__label {
    text-transform: none;
    margin-bottom: 16px;
    font-size: 16px;
    line-height: 1.6;
    color: $color-gray-25;
    letter-spacing: -0.25px;
    font-weight: 400;
  }
  :deep() .autocomplete__input-group {
    svg {
      color: $color-gray-6C;
    }
  }
}

.edit-box {
  margin-top: 24px;
  border: 1px solid #ccd2e0;
  border-radius: 3px;
  box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.12);
  padding: 24px;

  h2 {
    font-weight: 500;
  }
}
</style>
