<script>
import { mapActions } from 'vuex';

import EzInput from '@/components/ui/Input';
import VSelectSearchUnit from '@/components/v3/patterns/VSelectSearchUnit';
import AddUnitModal from '@/components/v3/patterns/VUnitsSettings/AddUnitModal';
import RemoveUnitModal from '@/components/v3/patterns/VUnitsSettings/RemoveUnitModal';
import EzSpinner from '@/components/ui/Spinner/EzSpinner.vue';
import { UNIT_TYPE_FRACTIONAL } from '@/util/constants';

export default {
  components: {
    EzInput,
    VSelectSearchUnit,
    AddUnitModal,
    RemoveUnitModal,
    EzSpinner,
  },
  props: {
    // This object **will get updated** directly!
    product: {
      type: Object,
      require: true,
    },
    venueId: {
      type: Number,
      required: false,
      default: null,
    },
    invoiceId: {
      type: Number,
      required: false,
      default: null,
    },
  },
  data() {
    return {
      units: [],
      unitToDelete: {},
      unitToUpdate: {},
      selectedUnit: {},
      UNIT_TYPE_FRACTIONAL,
    };
  },
  computed: {
    selectedUnitId() {
      return this.selectedUnit?.id;
    },
  },
  methods: {
    ...mapActions('entities/products', ['adminFetchUnits']),
    async fetchUnits() {
      const {
        data: { data },
      } = await this.adminFetchUnits({
        distributorId: this.invoice?.distributor?.id ?? null,
        venueId: this.venueId,
        term: this.unitTerm,
      });

      this.units = data;
      localStorage.setItem('adminUnits', JSON.stringify(data));
    },
    focusNameInput() {
      this.$refs.nameInput.focus();
    },
    openUnitModal() {
      this.$refs.addUnitModal.open();
    },
    editUnit(unit) {
      if (!unit.owned) return;
      this.unitToUpdate = unit;
      this.openUnitModal();
    },
    onUnitModalClose() {
      this.unitToUpdate = {};
    },
    deleteUnit(unit) {
      this.unitToDelete = unit;
      this.$refs.removeUnit.open();
    },
    onRemoveClose() {
      if (this.selectedUnit?.id === this.unitToDelete?.id) {
        this.$refs.chooseUnit.reset();
        this.preSelectData(this.product);
        this.updateUnits();
      }
      this.unitToDelete = {};
    },
    selectUnit(unit) {
      this.selectedUnit = unit;
      if (this.isFractionalSelected) {
        this.quantityIncrement = 0.01;
        this.minimumQuantity = 0.01;
      } else {
        this.quantityIncrement = 1.0;
        this.minimumQuantity = 1.0;
      }
      if (!this.isUnitDiff) this.selectedPriceUnit = this.selectedUnit;
      this.updateUnits();
    },
    // Unit for create new product
    async onUnitAdd() {
      await this.fetchUnits();
    },

    updateUnits() {
      this.product.orderingUnit = this.selectedUnit;
    },
  },
  watch: {
    product: {
      deep: true,
      handler() {
        this.product.isDirty = true;
      },
    },
    invoiceId: {
      async handler(val, newVal) {
        if (val !== null && val !== newVal) await this.fetchUnits();
      },
    },
    units: {
      async handler(val) {
        if (val.length) {
          await this.$nextTick();
          this.focusNameInput();
        }
      },
    },
  },
  beforeMount() {
    this.selectedUnit = this.product?.orderingUnit || {};
    const adminUnits = JSON.parse(localStorage.getItem('adminUnits'));
    if (adminUnits && adminUnits.length) {
      this.units = adminUnits;
    } else {
      this.fetchUnits();
    }
  },
};
</script>
<template>
  <div class="wrapper">
    <div class="content" v-if="units.length">
      <div class="new-product">
        <ez-input
          formKey="new-product"
          name="name"
          ref="nameInput"
          id="productName"
          label="Product name"
          :value="this.product.name"
          placeholder="Enter product name"
          @onChange="$event => (this.product.name = $event)"
          class="mb-12"
        />
        <ez-input
          formKey="new-product"
          name="sku"
          label="SKU number"
          :value="this.product.sku"
          placeholder="Enter SKU Number"
          @onChange="$event => (this.product.sku = $event)"
          class="mb-12"
        />
        <v-select-search-unit
          ref="chooseUnit"
          :data="units"
          label="Sales Unit"
          placeholder="Choose Unit"
          name="orderingUnitId"
          search-property="label"
          has-actions
          @onEdit="editUnit"
          @onRemove="deleteUnit"
          @onAddNew="openUnitModal"
          @onSelect="selectUnit"
          :selected-id="selectedUnitId"
        />
      </div>
    </div>
    <div class="content-loader" v-else>
      <ez-spinner />
    </div>

    <add-unit-modal
      ref="addUnitModal"
      :unit="{ type: UNIT_TYPE_FRACTIONAL, ...unitToUpdate, ...(venueId ? { venueId } : {}) }"
      @onClose="onUnitModalClose"
      @success="onUnitAdd"
    />
    <remove-unit-modal
      ref="removeUnit"
      @onClose="onRemoveClose"
      @success="() => $emit('successDeleteUnit')"
      :unit="unitToDelete"
    />
  </div>
</template>

<style lang="scss" scoped>
.wrapper {
  position: relative; // Because of loader
  min-height: 180px; // Approximetly the height when form is loaded
}

.content-loader {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 50px 0;
}

.new-product {
  :deep() .ez-select {
    width: 100%;
  }

  :deep() .ez-image-upload {
    .entity-info {
      &__preview {
        margin: 0 auto;
      }
    }
  }
}
</style>
