<template>
  <ez-wizard fullWidth @exit="onWizardExit">
    <template #title>
      <span v-if="title">{{ title }}</span>
      <v-badge v-if="type" class="title-badge ml-8">{{ type }}</v-badge>
    </template>
    <div class="processing-wrapper">
      <div class="processing-pdf">
        <FileSlider v-if="invoice?.invoices?.length" :data="invoice.invoices" />
      </div>
      <div class="processing-content">
        <div>
          <div class="note-wrapper">
            <ez-textarea
              class="customer-note"
              name="customerNote"
              formKey="pending-invoice"
              :value="invoice?.clearingNote ?? null"
              placeholder="Add Customer Note"
              @onChange="onCustomerNoteChange"
              :rows="1"
            />
            <div class="prefix">
              <font-awesome-icon v-if="invoice?.clearingNote" icon="exclamation-circle" />
              <font-awesome-icon v-else icon="plus" />
            </div>
          </div>
          <div v-if="!isInvoiceCompleted" class="processing-actions">
            <ez-button
              type="secondary"
              :class="{ selected: note === 'missing_page' }"
              @click="selectNote('missing_page')"
            >
              Missing Page
            </ez-button>
            <ez-button
              type="secondary"
              :disabled="isInvoiceCompleted"
              :class="{ selected: note === 'blurry_image' }"
              @click="selectNote('blurry_image')"
            >
              Blur Invoice
            </ez-button>
            <ez-button-dropdown
              buttonType="secondary"
              :class="{ selected: note === 'not_valid' || note === 'duplicate' }"
              @click="selectNote('not_valid')"
            >
              <template>Not Valid</template>
              <template #icon>
                <font-awesome-icon icon="angle-down" />
              </template>
              <template #dropdown>
                <ez-button
                  @click="selectSubNote('not_valid_delivery_order', 'not_valid')"
                  type="secondary"
                  :class="{ selected: subNoteKey === 'not_valid_delivery_order' }"
                  >Delivery Order</ez-button
                >
                <ez-button
                  @click="selectSubNote('not_valid_unknown_document', 'not_valid')"
                  type="secondary"
                  :class="{ selected: subNoteKey === 'not_valid_unknown_document' }"
                  >Unknown Document</ez-button
                >
                <ez-button
                  @click="selectSubNote('not_valid_unsupported_language', 'not_valid')"
                  type="secondary"
                  :class="{ selected: subNoteKey === 'not_valid_unsupported_language' }"
                  >Unsupported Language</ez-button
                >
                <ez-button
                  @click="selectSubNote('not_valid_math_error', 'not_valid')"
                  :class="{ selected: subNoteKey === 'not_valid_math_error' }"
                  type="secondary"
                  >Supplier Math Error</ez-button
                >
                <ez-button
                  @click="selectSubNote('not_valid_duplicate', 'not_valid')"
                  :class="{
                    selected: subNoteKey === 'not_valid_duplicate' || note === 'duplicate',
                  }"
                  type="secondary"
                  >Duplicate</ez-button
                >
                <ez-button
                  v-if="invoice?.type === 'order'"
                  @click="selectSubNote('not_valid_wrong_order', 'not_valid')"
                  type="secondary"
                  :class="{ selected: subNoteKey === 'not_valid_wrong_order' }"
                  >Uploaded to wrong order</ez-button
                >
              </template>
            </ez-button-dropdown>
            <ez-button-dropdown
              buttonType="secondary"
              @click="selectNote('missing_info')"
              :class="{ selected: note === 'missing_info' }"
            >
              <template>Missing Info</template>
              <template #icon>
                <font-awesome-icon icon="angle-down" />
              </template>
              <template #dropdown>
                <ez-button
                  @click="selectSubNote('missing_info_invoice_number', 'missing_info')"
                  :class="{ selected: subNoteKey === 'missing_info_invoice_number' }"
                  type="secondary"
                  >Invoice Number</ez-button
                >
                <ez-button
                  @click="selectSubNote('missing_info_product_details', 'missing_info')"
                  :class="{ selected: subNoteKey === 'missing_info_product_details' }"
                  type="secondary"
                  >Product Details</ez-button
                >
                <ez-button
                  @click="selectSubNote('missing_info_prices', 'missing_info')"
                  :class="{ selected: subNoteKey === 'missing_info_prices' }"
                  type="secondary"
                  >Prices</ez-button
                >
                <ez-button
                  @click="selectSubNote('missing_info_unreadable', 'missing_info')"
                  :class="{ selected: subNoteKey === 'missing_info_unreadable' }"
                  type="secondary"
                  >Unreadable</ez-button
                >
              </template>
            </ez-button-dropdown>
            <div class="dropdown-button">
              <ez-button
                :disabled="isCompleting"
                @click="completePendingInvoice"
                id="completeAndNext"
              >
                Complete & Next
              </ez-button>
              <ez-button-dropdown buttonType="primary" :disabled="isCompleting">
                <template #icon>
                  <font-awesome-icon icon="angle-down" />
                </template>
                <template #dropdown>
                  <ez-button @click="completeAndReturnToQueue" type="secondary"
                    >Complete and return to Queue</ez-button
                  >
                  <ez-button @click="completeAndReturnToQueue(false)" type="secondary"
                    >Cancel and return to Queue</ez-button
                  >
                </template>
              </ez-button-dropdown>
            </div>
          </div>
        </div>
        <div class="processing-form">
          <InvoiceDetails
            @editSupplierStart="() => (editSupplier = true)"
            @editSupplierStop="() => (editSupplier = false)"
            :venueId="venueId"
            :invoice="invoice"
          />
          <SelectedProducts v-show="!editSupplier" :invoice="invoice" />
          <AvailableProducts
            :venueId="venueId"
            :invoice="invoice"
            @productCreated="selectQuantity"
            v-show="!editSupplier"
          />
          <InvoiceCalculation :invoice="invoice" />
        </div>
      </div>
    </div>
  </ez-wizard>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import { debounce } from '@/util/utils';
import { ADMIN_INVOICE_PROCESSING_STATUS_PROCESSED, LOADING_KEY } from '@/util/constants';
import EzWizard from '@/components/layout/Wizard.vue';
import EzTextarea from '@/components/ui/Textarea/EzTextarea.vue';
import EzButton from '@/components/ui/Button/EzButton.vue';
import FileSlider from '@/components/ui/FileSlider/FileSlider';
import flash from '@/components/ui/FlashMessage';
import uuid from 'uuid/v4';
import dayjs from 'dayjs';
import EzButtonDropdown from '@/components/ui/ButtonDropdown/EzButtonDropdown.vue';
import httpService from '@/api/http';
import VBadge from '@/components/v3/elements/VBadge';
import InvoiceDetails from './InvoiceDetails';
import SelectedProducts from './SelectedProducts';
import AvailableProducts from './AvailableProducts';
import InvoiceCalculation from './InvoiceCalculation';

export default {
  components: {
    EzWizard,
    EzButton,
    FileSlider,
    InvoiceDetails,
    SelectedProducts,
    AvailableProducts,
    InvoiceCalculation,
    EzButtonDropdown,
    EzTextarea,
    VBadge,
  },
  props: {
    invoiceId: {
      type: Number,
      required: true,
    },
    status: {
      type: String,
      required: false,
      default: null,
    },
  },
  data() {
    return {
      invoice: {},
      note: null,
      subNoteKey: null,
      editSupplier: false,
    };
  },
  computed: {
    ...mapGetters('loading', ['getLoading']),
    isInvoiceCompleted() {
      return this.invoice.processingStatus === ADMIN_INVOICE_PROCESSING_STATUS_PROCESSED;
    },
    productsToSubmit() {
      return this.invoice.order.products.map(product => ({
        id: product.productId || product.id,
        quantity: product.quantity || 0,
        price: product.price,
        tax: product.tax || 0,
        ...(product.productId ? { orderedProductId: product.id } : {}),
      }));
    },
    isCompleting() {
      return this.getLoading(LOADING_KEY.ADMIN_COMPLETE_PENDING_INVOICE);
    },
    type() {
      if (this.invoice.type === 'invoice') return 'Invoice';
      if (this.invoice.type === 'order') return 'Order';
      if (this.invoice.type === 'credit_note') return 'Credit Note';
      return null;
    },
    venueId() {
      return this.invoice?.venue?.id;
    },
    title() {
      if (this.invoice?.venue?.name)
        return `${this.invoice?.group?.name} ${
          this.invoice?.venue?.name ? `- ${this.invoice?.venue?.name}` : ''
        } ${this.invoice?.venue?.legalName ? `(${this.invoice?.venue?.legalName})` : ''}`;
      return null;
    },
  },
  methods: {
    ...mapActions('entities/orders', [
      'adminFetchPendingInvoice',
      'adminUpdateClearingNote',
      'adminCompletePendingInvoice',
      'adminFetchNextPendingInvoice',
      'adminReleasePendingInvoice',
    ]),
    onWizardExit() {
      this.$router.push({ name: 'admin-clearing-app-processing' });
    },
    onCustomerNoteChange(val) {
      const textarea = document.querySelector('.customer-note textarea');
      if (textarea) textarea.style.height = `${textarea.scrollHeight}px`;
      this.onNoteChange(val);
    },
    onNoteChange: debounce(async function deb(val) {
      if (this.invoice.clearingNote !== val) {
        try {
          await this.adminUpdateClearingNote({
            invoiceId: this.invoiceId,
            clearingNote: val,
          });
          this.invoice.clearingNote = val;
          flash.success({ title: 'Clearing Note succesfully updated.' });
        } catch (e) {
          flash.error({
            title: 'Something went wrong!',
          });
        }
      }
    }, 500),
    async completePendingInvoice(complete = true) {
      const hasNew =
        this.invoice?.order?.distributor?.isNew ||
        this.invoice?.order?.products?.filter(item => item.isNew).length > 0;
      try {
        if (complete) {
          await this.adminCompletePendingInvoice({
            body: {
              ...this.invoice,
              ...(this.note ? { note: this.note } : {}),
              ...(this.subNoteKey ? { subNote: this.subNoteKey } : {}),
              addToTraining: false,
              order: {
                ...this.invoice.order,
                products: this.productsToSubmit,
                distributorId: this.invoice.order?.distributor?.id,
                orderedAt: this.invoice.order.orderedAt
                  ? dayjs(this.invoice.order.orderedAt).format('YYYY-MM-DD')
                  : null,
                ...(this.invoice.order.tax === null
                  ? { tax: this.invoice.order?.distributor?.tax || 0 }
                  : {}),
              },
              hasNewSupplierOrProduct: hasNew,
            },
            invoiceId: this.invoiceId,
            formKey: 'pending-invoice',
          });
          flash.success({ title: 'Pending invoice successfully submited' });
          if (this.note === 'missing_page' && this.$permission.has('adminMergeInvoices')) {
            await httpService.put(`admin/pending-invoices/${this.invoiceId}/user`);
            this.$router.push({
              name: 'admin-clearing-app-merge',
              params: {
                id: this.invoiceId,
              },
              query: {
                venueId: this.venueId,
              },
            });
            return;
          }
        } else {
          this.adminReleasePendingInvoice({ invoiceId: this.invoiceId });
        }
        let filters = {};
        if (localStorage.getItem('processingFilters')) {
          filters = JSON.parse(localStorage.getItem('processingFilters'));
        }
        const { data } = await this.adminFetchNextPendingInvoice(filters);
        if (data.data?.id) {
          await this.openSinglePendingInvoice(
            {
              id: data.data.id,
              venue: data.data.venue,
              noteKey: data.data.noteKey,
            },
            data,
          );
        } else {
          this.$router.push({ name: 'admin-clearing-app-processing' });
        }
      } catch (e) {
        flash.error({
          title: 'Something went wrong!',
          message: e.response?.data?.error?.message || '',
        });
      }
    },
    async completeAndReturnToQueue(complete = true) {
      const hasNew =
        this.invoice?.order?.distributor?.isNew ||
        this.invoice?.order?.products?.filter(item => item.isNew).length > 0;
      try {
        if (complete) {
          await this.adminCompletePendingInvoice({
            body: {
              ...this.invoice,
              ...(this.note ? { note: this.note } : {}),
              ...(this.subNoteKey ? { subNote: this.subNoteKey } : {}),
              addToTraining: false,
              order: {
                ...this.invoice.order,
                products: this.productsToSubmit,
                distributorId: this.invoice.order?.distributor?.id,
                orderedAt: this.invoice.order.orderedAt
                  ? dayjs(this.invoice.order.orderedAt).format('YYYY-MM-DD')
                  : null,
                ...(this.invoice.order.tax === null
                  ? { tax: this.invoice.order?.distributor?.tax || 0 }
                  : {}),
              },
              hasNewSupplierOrProduct: hasNew,
            },
            invoiceId: this.invoiceId,
            formKey: 'pending-invoice',
          });
          flash.success({ title: 'Pending invoice successfully submited' });
        } else {
          this.adminReleasePendingInvoice({ invoiceId: this.invoiceId });
        }
        this.$router.push({ name: 'admin-clearing-app-processing' });
      } catch (e) {
        flash.error({
          title: 'Something went wrong!',
          message: e.response?.data?.error?.message || '',
        });
      }
    },
    async openSinglePendingInvoice({ id }, data) {
      this.$router.push({
        name: 'admin-clearing-app-processing-single',
        params: {
          id,
        },
        query: {
          status: null,
        },
      });
      this.invoice = this.setupInvoice({});
      await this.$nextTick();
      this.invoice = this.setupInvoice(data.data);
      this.note = data.data.noteKey;
      this.subNoteKey = data.data.subNoteKey;
    },
    selectNote(note) {
      if (this.note === note || (this.note === 'duplicate' && note === 'not_valid')) {
        this.note = null;
        this.subNoteKey = null;
      } else this.note = note;
    },
    selectSubNote(subNote, note) {
      if (
        this.subNoteKey === subNote ||
        (this.note === 'duplicate' && subNote === 'not_valid_duplicate')
      ) {
        this.subNoteKey = null;
        this.note = null;
      } else {
        this.subNoteKey = subNote;
        this.note = note;
      }
    },
    async selectQuantity() {
      await this.$nextTick();
      const quantityInputs = document.querySelectorAll('.quantity__input input');
      const quantityInput = quantityInputs[quantityInputs.length - 1];
      if (quantityInput) quantityInput.focus();
    },
    setupInvoice(data) {
      delete data.note;
      if (!data.order) {
        return {
          // Merge from API
          ...data,
          // Add defaults
          order: {
            products: [],
            taxCalculation: data?.order?.distributor?.taxCalculation || 'exclusive',
            orderDiscountAmount: 0,
            amountAdjustment: 0,
            deliveryFee: 0,
            taxAdjustment: 0,
            distributor: {},
            tax: null,
          },
        };
      }
      data.order.products = [];
      if (data.order.orderedProducts?.length) {
        data.order.orderedProducts.forEach(product => {
          let totalAmount = product.totalPrice;
          if (data.order.taxCalculation === 'exclusive') {
            if (product.tax === null) totalAmount += totalAmount * (data.order.tax / 100 || 0);
            else totalAmount += totalAmount * (product.tax / 100 || 0);
          }
          data.order.products.push({
            internalId: uuid(),
            ...product,
            totalAmount,
            totalPrice: product.quantity * product.price,
          });
        });
      }
      data.order = {
        taxCalculation: data?.order?.distributor?.taxCalculation || 'exclusive',
        orderDiscountAmount: 0,
        amountAdjustment: 0,
        deliveryFee: 0,
        taxAdjustment: 0,
        distributor: {},
        tax: null,
        ...data.order,
      };
      return data;
    },
  },
  async beforeMount() {
    this.invoice = this.setupInvoice({});
    let filters = {};
    if (localStorage.getItem('processingFilters')) {
      filters = JSON.parse(localStorage.getItem('processingFilters'));
    }
    const { data } = await this.adminFetchNextPendingInvoice({
      invoiceId: this.invoiceId,
      status: this.status,
      ...filters,
    });
    if (data.data.id !== this.invoiceId) {
      this.$router.push({
        name: 'admin-clearing-app-processing-single',
        params: {
          id: data.data.id,
        },
        query: {
          status: null,
        },
      });
      flash.success({ title: data.data.queueMessage });
    }
    this.invoice = this.setupInvoice(data.data);
    this.note = data.data.noteKey;
    this.subNoteKey = data.data.subNoteKey;
  },
  created() {
    // define a CMD + D handler
    function focusComplete(e) {
      const completeButton = document.querySelector('#completeAndNext');
      if (completeButton) {
        if (e.metaKey && e.keyCode === 68) {
          e.preventDefault();
          completeButton.focus();
        } else if (e.altKey && e.keyCode === 68) {
          e.preventDefault();
          completeButton.focus();
        }
      }
    }
    document.addEventListener('keydown', focusComplete, false);
  },
  watch: {
    'invoice.clearingNote': {
      immediate: true,
      async handler(newVal, oldVal) {
        if (newVal !== oldVal) {
          await this.$nextTick();
          const textarea = document.querySelector('.customer-note textarea');
          if (textarea) textarea.style.height = `${textarea.scrollHeight}px`;
        }
      },
    },
  },
};
</script>

<style scoped lang="scss">
$purple-inner-color: #eedff4;
$purple-text-color: #7833a2;

:deep() .ez-wizard__main {
  max-width: none;
  padding: 32px 60px;

  .ez-wizard__page-header {
    margin: 0;
  }
}

:deep() .title-badge.e-badge {
  display: inline;
}

.processing-wrapper {
  display: flex;
  align-items: flex-start;
}

.processing-pdf {
  position: sticky;
  top: 80px;
  flex: 1 1 100%;
  min-width: 576px;
  margin-right: 48px;
}

.processing-content {
  flex: 1 1 100%;
  max-width: 980px;
}

.note-wrapper {
  position: relative;

  .prefix {
    position: absolute;
    top: 10px;
    left: 10px;
    color: $purple-text-color;
  }
}

:deep() .customer-note {
  textarea {
    border-radius: 1px 12px 12px 12px;
    border-color: $purple-inner-color;
    background-color: $purple-inner-color;
    border: 2px solid transparent;
    color: $purple-text-color;
    padding-left: 30px;
    display: block;
    width: 100%;
    overflow-y: hidden;
    resize: none;
    min-height: 40px;
    line-height: 20px;

    &::placeholder {
      color: $purple-text-color;
    }
  }

  textarea:not(:read-only):focus {
    border-color: $purple-text-color;
  }
}

.processing-actions {
  display: flex;
  justify-content: flex-end;
  margin-top: 32px;
  flex-wrap: wrap;
  row-gap: 12px;

  :deep() .button {
    min-width: 146px;
    margin-left: 32px;
    display: flex;
    align-items: center;
    justify-content: center;

    &.button--secondary {
      border: 2px solid $color-gray-F5;
      &.selected {
        border: 2px solid $color-primary-blue;
      }
    }
  }

  :deep() .button-dropdown.button-dropdown--secondary {
    border: 2px solid $color-gray-F5;
    &.selected {
      border: 2px solid $color-primary-blue;
    }
    margin-left: 32px;
    .trigger {
      padding-left: 6px;
    }
    .button-dropdown__toggle-container .button {
      min-height: 32px;
    }
    .button {
      min-width: initial;
      margin-left: 0;
      height: 32px;
      line-height: 32px;
      padding-right: 6px;
    }
    .button.trigger {
      padding-right: 12px;
    }

    .button-dropdown__dropdown {
      .button.button--secondary {
        min-width: initial;
        margin-left: 0;
        justify-content: flex-start;
        border: none;
        background-color: #fff;

        &:hover {
          background-color: #f5f6fa;
        }
        &.selected {
          color: $color-primary-blue;
        }
      }
    }
  }
  :deep() .button-dropdown button.button--secondary {
    border: none;
  }
}

.processing-form {
  margin-top: 32px;
  padding: 24px 40px;
  border-radius: 3px;
  border: 1px dashed #e1e5ed;
}

:deep() .pdf-placeholder {
  height: 560px;
}

@media (min-width: 1920px) {
  :deep() .pdf-placeholder {
    height: 960px;
  }
}

.dropdown-button {
  :deep() .button.button--primary {
    padding-right: 6px;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
    min-width: initial;
    display: inline-block;
  }
  :deep() .button-dropdown.button-dropdown--primary {
    padding-left: 0px;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;

    .button.button--primary {
      padding-left: 6px;
      padding-right: 12px;
      border-top-left-radius: 0;
      border-bottom-left-radius: 0;
      border-top-right-radius: 3px;
      border-bottom-right-radius: 3px;
      margin-left: 0;
    }
    .button.button--secondary {
      min-width: initial;
      margin-left: 0;
      justify-content: flex-start;
      border: none;
      background-color: #fff;

      &:hover {
        background-color: #f5f6fa;
      }
    }
  }
}
</style>
