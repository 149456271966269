<template>
  <span class="premium">
      <img src="@/assets/responsive-badge.svg">
  </span>
</template>

<script>
/**
 * PremiumBadge
 * @version 1.0.0
 * @since 2.3
 */

export default {};
</script>

<style lang="scss" scoped>
.premium {
  display: inline-block;
  margin-left: 4px;

  img { width: 16px; }
}
</style>
