<script>
import uuid from 'uuid/v4';
import { mapActions } from 'vuex';

import { falsy } from '@/util/utils';

import EzButton from '@/components/ui/Button/EzButton';
import EzAutocomplete from '@/components/ui/Autocomplete/EzAutocomplete.vue';
import httpService from '@/api/http';
import flash from '@/components/ui/FlashMessage';
import EzLoader from '@/components/ui/Loader/EzLoader';
import StatusBadge from '@/views/common/status-badge/StatusBadge.vue';
import ProductForm from './ProductForm.vue';

export default {
  components: {
    EzAutocomplete,
    EzButton,
    ProductForm,
    EzLoader,
    StatusBadge,
  },
  props: {
    invoice: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      areProductsShown: false,
      isCreateProduct: false,
      formValid: false,
      term: '',
      unitTerm: '',
      meta: {},
      products: [],
      productsToBeAdded: [],
      newProduct: {
        name: '',
        sku: '',
        orderingUnit: {
          id: 10,
          name: 'UOM',
          type: 'fractional',
          abbreviation: null,
          quantity: 1,
          increment: 0,
          label: 'UOM',
        },
      }, // Flow for creating a new product
      loadingNewProductRequest: false,

      units: [],
      unitToDelete: {},
      unitToUpdate: {},
      selectedUnit: {},
      selectedPriceUnit: {},
      quantityIncrement: 1,
      minimumQuantity: 1,
    };
  },
  computed: {
    hasDistributor() {
      return !!this.invoice?.order?.distributor?.id;
    },
    isNewProductValid() {
      return (
        !falsy(this.newProduct?.name) ||
        !falsy(this.newProduct?.sku) ||
        !falsy(this.newProduct?.orderingUnit)
      );
    },
  },
  methods: {
    ...mapActions('entities/products', ['fetchAdminDistributorProducts']),

    clearState() {
      this.term = '';
      this.meta = {};
      this.products = [];
      this.productsToBeAdded = [];
    },

    // Forms
    submitNewProduct() {
      this.$refs.formProduct.submit();
    },
    isFormValid(valid) {
      this.formValid = valid;
    },
    isProductSelected(id) {
      return !!this.productsToBeAdded.filter(item => item.id === id).length;
    },
    /**
     * Products returned from the API don't have tax set.
     * Also we need to calulate the total amount.
     *
     * Because you can have same products added to the order,
     * create an internal ID that can be used for updating the
     * right objects.
     * @param {Product} product
     */
    remapProduct(product) {
      const quantity = null;
      const tax = this.invoice?.order?.tax || this.invoice?.order?.distributor?.tax || 0;
      return {
        internalId: uuid(),
        ...(this.invoice?.order?.taxCalculation === 'exclusive'
          ? { totalAmount: quantity * product.price * (1 + tax / 100) }
          : { totalAmount: quantity * product.price }),
        totalPrice: quantity * product.price,
        ...product,
        quantity,
        tax: product.tax === null ? tax : product.tax,
      };
    },
    async onProductSelect(prod) {
      if (prod.first) {
        this.isCreateProduct = true;
        this.newProduct.name = prod.query;
        this.newProduct.orderingUnit = {
          id: 10,
          name: 'UOM',
          type: 'fractional',
          abbreviation: null,
          quantity: 1,
          increment: 0,
          label: 'UOM',
        };
        this.newProduct.sku = '';
        this.$refs.newProduct.selectedUnit = this.newProduct.orderingUnit;
        await this.$nextTick();
        this.$refs.newProduct.focusNameInput();
      } else {
        this.invoice.order.products.push(this.remapProduct(prod.selected));
        this.$emit('productCreated');
      }
    },
    selectFistAvailableProduct(results) {
      this.$refs.autocomplete.down();
      this.$refs.autocomplete.down();
      let index = 0;
      let product = results.results[index];
      while (product && this.isProductAdded(product)) {
        this.$refs.autocomplete.down();
        index += 1;
        product = results.results[index] || null;
      }
    },
    async addNewProducts() {
      if (!this.hasDistributor) {
        flash.error({
          title: 'Distributor not selected!',
        });
        return;
      }
      this.loadingNewProductRequest = true;
      try {
        const {
          data: { data },
        } = await httpService.post(
          `/admin/distributors/${this.invoice?.order?.distributor?.id}/products`,
          {
            name: this.newProduct.name,
            sku: this.newProduct.sku,
            orderingUnitId: this.newProduct.orderingUnit.id,
            priceUnitId: this.newProduct.orderingUnit.id,
            byVenueId: this.invoice.venue.id,
            temp: true,
          },
          {
            headers: {
              formKey: 'new-product',
            },
          },
        );
        this.invoice.order.products.push(
          this.remapProduct({
            ...data,
            isNew: true,
          }),
        );
        this.isCreateProduct = false;
        this.$emit('productCreated');
      } finally {
        this.loadingNewProductRequest = false;
      }
      this.areProductsShown = false;
      this.clearState();
    },
    isProductAdded(result) {
      return this.invoice.order.products.filter(
        item => item.id === result.id || item.productId === result.id,
      ).length;
    },
  },
  created() {
    // define a CMD + P handler
    function focusAddProduct(e) {
      const addProductInput = document.querySelector('#addProductInput input');
      if (addProductInput) {
        if (e.metaKey && e.keyCode === 80) {
          e.preventDefault();
          addProductInput.focus();
        } else if (e.altKey && e.keyCode === 80) {
          e.preventDefault();
          addProductInput.focus();
        }
      }
    }
    // register the handler
    document.addEventListener('keydown', focusAddProduct, false);
  },
};
</script>
<template>
  <div class="mt-24 products">
    <ez-autocomplete
      v-if="hasDistributor && !isCreateProduct"
      id="addProductInput"
      ref="autocomplete"
      placeholder="Search for a product"
      :source="`admin/distributors/${invoice?.order?.distributor?.id}/search/products`"
      searchProperty="name"
      label="Add Products"
      @selected="onProductSelect"
      @results="selectFistAvailableProduct"
    >
      <template #icon>
        <font-awesome-icon icon="search" />
      </template>
      <template #firstResult="{ input }">
        <li>
          <div class="create-new-supplier">
            <div class="badge mr-8">
              <font-awesome-icon icon="plus" transform="shrink-6" />
            </div>
            <span>Create New "{{ input }}" Product</span>
          </div>
        </li>
      </template>
      <template #result="{ result }">
        <div class="venue-result">
          <img :src="result.image" alt="" class="venue-result__image" />
          <div class="venue-result__info">
            <div class="venue-result__title">
              <span :title="result.name">{{ result.name }}</span>
            </div>
            <div class="venue-result__label mt-4">
              <span>{{ result.orderingUnit.label }}</span>
              <span v-if="result.sku" class="mx-4">&#8226;</span>
              <span>{{ result.sku }}</span>
            </div>
          </div>
          <status-badge class="ml-8" v-if="isProductAdded(result)" status="added" />
        </div>
      </template>
    </ez-autocomplete>
    <div v-show="isCreateProduct" :class="['products__box', { 'new-supplier': isCreateProduct }]">
      <div class="new-product-wrapper">
        <ez-loader :show="loadingNewProductRequest" />
        <h2 class="title">Create New Product</h2>
        <product-form
          ref="newProduct"
          :product="newProduct"
          :venue-id="this.invoice?.venue?.id"
          :invoice-id="this.invoice.id"
        />

        <footer class="u-text-right mt-24">
          <ez-button @click="() => (isCreateProduct = false)" type="link" class="mr-4" tabindex="-1"
            >Cancel</ez-button
          >
          <ez-button @click="addNewProducts" :disabled="!isNewProductValid">
            Create Product
          </ez-button>
        </footer>
      </div>
    </div>
  </div>
</template>
<style lang="scss" scoped>
.products {
  .product-info {
    display: flex;
    flex-direction: column;
  }

  .cell-product-container {
    @extend %flex-center;
    justify-content: flex-start;
  }

  .search-box__title {
    @include font-size(16px, 22px);
    margin: 0;
  }

  .search-box__header {
    @extend %flex-center;
    justify-content: space-between;
    padding: 24px;
  }

  .search-box__body {
    height: 280px;
    overflow: auto;

    :deep() table.table thead th:first-child,
    table.table tbody td:first-child {
      padding-left: 12px;
    }

    :deep() table.table thead th:last-child,
    table.table tbody td:last-child {
      padding-right: 12px;
    }
  }

  .search-box__actions {
    @extend %flex-space;
    height: 56px;
    padding: 0 12px;
    border-top: 1px solid #ccd2e0;
  }

  .search-box__msg {
    @extend %flex-center;
    @include font-size(14px, 22px);
    color: $color-gray-6C;
  }

  &__box {
    flex: 1 1 100%;
    padding: 0;
    border: 1px solid #ccd2e0;
    border-radius: 3px;
    box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.12);

    .empty-state-suppliers {
      margin-top: 110px;
    }

    .result-item {
      display: flex;
      align-items: center;
      justify-content: flex-start;
    }

    .entity-info {
      margin-left: 8px;
    }

    .price {
      @include font-size(16px);
      font-weight: 600;
      color: $color-gray-25;
      padding-right: 12px;
    }

    .actions {
      width: 100%;
      display: flex;
      justify-content: center;
      margin-top: 24px;
    }
  }

  .create-new-supplier {
    display: flex;
    align-items: center;

    .badge {
      width: 32px;
      height: 32px;
      border-radius: 50%;
      @extend %flex-center;
      justify-content: center;
      background-color: #e2eaff;
      color: #4d7cfe;
      @include font-size(20px);
    }
  }

  .new-supplier {
    border-style: solid;
    padding: 0;
  }

  .empty-state {
    margin-top: 64px;
  }

  .products-table {
    margin-top: 64px;

    .product-info {
      display: flex;
      flex-direction: column;
      color: $color-gray-25;
    }
  }

  .input-warning {
    display: flex;
    align-items: center;

    &__icon {
      margin-left: 6px;
    }
  }
}

.venue-result {
  @extend %flex-center;

  &__image {
    @include size(32px);
    border-radius: 3px;
    border: 1px solid #dee1e4;
  }

  &__info {
    margin-left: 8px;
  }

  &__titele {
    color: #252631;
    font-size: 14px;
    font-weight: 500;
    line-height: 16px;
  }

  &__label {
    color: #6c7995;
    font-size: 12px;
    font-weight: 500;
    letter-spacing: 0;
    line-height: 14px;
  }
}

:deep() .autocomplete__input-group {
  svg {
    color: $color-gray-6C;
  }
}

.new-product-wrapper {
  padding: 24px;
  position: relative;

  .title {
    font-weight: 500;
  }

  :deep() .loader {
    position: absolute;
  }
}
</style>
