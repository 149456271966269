<script>
import EzMaskInputSimple from '@/components/ui/MaskInputSimple/EzMaskInputSimple';
import { TAX_CALCULATION_OPTIONS } from '@/util/constants';
import VStatusDropdown from '@/components/v3/patterns/VStatusDropdown';
import { getSum, getTaxFee, getTotalWithTax } from '@/util/utilsFinCalculator';

export default {
  components: {
    EzMaskInputSimple,
    VStatusDropdown,
  },
  props: {
    invoice: {
      type: Object,
      required: true,
    },
  },
  methods: {
    onDiscountChange(val) {
      this.invoice.order.orderDiscountAmount = val;
    },
    onTaxAdjustmentChange(val) {
      this.invoice.order.taxAdjustment = val;
    },
    onDeliveryFeeChange(val) {
      this.invoice.order.deliveryFee = val;
    },
    onPriceAdjustment(val) {
      this.invoice.order.amountAdjustment = val;
    },
    updateTaxCalculation(val) {
      this.invoice.order.taxCalculation = val;
    },
  },
  computed: {
    currency() {
      return this.invoice?.order?.products?.[0]?.currency || this.invoice?.group?.currency;
    },
    orderSubtotal() {
      let subtotal = getSum({
        list: this.invoice.order.products,
        prop: 'totalAmount',
        roundToTwoDecimals: true,
      });
      subtotal -= getTaxFee({
        itemList: this.invoice.order.products,
        itemProp: 'totalAmount',
        deliveryFee: 0,
        tax: this.invoice.order.tax,
        taxCalculation: this.invoice?.order?.taxCalculation === 'exempt' ? 'exempt' : 'inclusive',
      });
      return subtotal;
    },
    taxFee() {
      const taxFee = getTaxFee({
        itemList: this.invoice.order.products,
        itemProp: 'totalAmount',
        ...(this.invoice?.order?.taxCalculation === 'inclusive'
          ? { discount: -this.invoice.order.orderDiscountAmount || 0 }
          : {}),
        deliveryFee: this.invoice.order?.deliveryFee || 0,
        deliveryFeeTaxable: this.invoice.order.distributor?.deliveryFeeWithoutTax,
        tax: this.invoice.order.tax || this.invoice.order.distributor?.tax || 0,
        taxCalculation: this.invoice?.order?.taxCalculation === 'exempt' ? 'exempt' : 'inclusive',
      });
      if (this.invoice?.order?.taxCalculation === 'exclusive') {
        const discountTax = getTaxFee({
          itemList: [],
          itemProp: 'price',
          deliveryFee: 0,
          discount: -this.invoice.order.orderDiscountAmount || 0,
          tax: this.invoice.order.tax || this.invoice.order.distributor?.tax || 0,
          taxCalculation: this.invoice?.order?.taxCalculation,
        });
        return taxFee + (this.invoice?.order?.taxAdjustment || 0) + discountTax;
      }
      return taxFee + (this.invoice?.order?.taxAdjustment || 0);
    },
    total() {
      const total = getTotalWithTax({
        itemList: this.invoice.order.products,
        itemProp: 'totalAmount',
        discount: -this.invoice?.order?.orderDiscountAmount || 0,
        amountAdjustment: this.invoice?.order?.amountAdjustment || 0,
        taxAdjustment: this.invoice?.order?.taxAdjustment || 0,
        deliveryFee: this.invoice.order?.deliveryFee || 0,
        deliveryFeeTaxable: this.invoice.order.distributor?.deliveryFeeWithoutTax,
        tax: this.invoice.order.tax || this.invoice.order.distributor?.tax || 0,
        taxCalculation: this.invoice?.order?.taxCalculation === 'exempt' ? 'exempt' : 'inclusive',
      });
      if (this.invoice?.order?.taxCalculation === 'exclusive') {
        const discountTax = getTaxFee({
          itemList: [],
          itemProp: 'price',
          deliveryFee: 0,
          discount: -this.invoice.order.orderDiscountAmount || 0,
          tax: this.invoice.order.tax || this.invoice.order.distributor?.tax || 0,
          taxCalculation: this.invoice?.order?.taxCalculation,
        });
        return total + discountTax;
      }
      return total;
    },
    discount() {
      const discountTax = getTaxFee({
        itemList: [],
        itemProp: 'price',
        deliveryFee: 0,
        discount: -this.invoice.order.orderDiscountAmount || 0,
        tax: this.invoice.order.tax || this.invoice.order.distributor?.tax || 0,
        taxCalculation: this.invoice?.order?.taxCalculation,
      });
      if (this.invoice?.order?.taxCalculation === 'inclusive') {
        return this.invoice.order.orderDiscountAmount + discountTax;
      }
      return this.invoice.order.orderDiscountAmount;
    },
    taxValue() {
      let taxValue =
        this.invoice?.order?.tax !== null && this.invoice?.order?.tax !== undefined
          ? this.invoice?.order?.tax
          : this.invoice?.order?.distributor?.tax || 0;
      this.invoice.order.products.forEach(prod => {
        if (prod.tax !== null && prod.tax !== taxValue) {
          taxValue = null;
          return taxValue;
        }
        return taxValue;
      });
      return taxValue;
    },
    taxCalculationOptions() {
      return TAX_CALCULATION_OPTIONS.map(item => ({
        ...item,
        name: item.value,
        selected: item.value === this.invoice?.order?.taxCalculation,
      }));
    },
  },
  created() {
    // define a CMD + A handler
    function focusAdjustment(e) {
      const adjustmentInput = document.querySelector('#adjustmentInput input');
      if (adjustmentInput) {
        if (e.metaKey && e.keyCode === 65) {
          e.preventDefault();
          adjustmentInput.focus();
        } else if (e.altKey && e.keyCode === 65) {
          e.preventDefault();
          adjustmentInput.focus();
        }
      }
    }
    document.addEventListener('keydown', focusAdjustment, false);
  },
};
</script>
<template>
  <div class="order-calculations mt-24">
    <div class="order-adjustments">
      <div class="order-adjustments__label">Add Discount</div>
      <ez-mask-input-simple
        :currency="currency"
        id="adjustmentInput"
        :value="invoice.order.orderDiscountAmount || 0"
        type="inline"
        @input="onDiscountChange"
        :precision="4"
        formKey="pending-invoice"
      />
      <div class="order-adjustments__label">Add Delivery Fee</div>
      <ez-mask-input-simple
        :currency="currency"
        :value="invoice.order.deliveryFee || 0"
        type="inline"
        @input="onDeliveryFeeChange"
        :precision="4"
        formKey="pending-invoice"
      />
      <div class="order-adjustments__label">Add Tax Adjustment</div>
      <ez-mask-input-simple
        :currency="currency"
        :value="invoice.order.taxAdjustment || 0"
        type="inline"
        @input="onTaxAdjustmentChange"
        :precision="4"
        formKey="pending-invoice"
      />
      <div class="order-adjustments__label">Add Adjustment</div>
      <ez-mask-input-simple
        :currency="currency"
        :value="invoice.order.amountAdjustment || 0"
        type="inline"
        @input="onPriceAdjustment"
        :precision="4"
        formKey="pending-invoice"
      />
    </div>
    <div class="order-total">
      <div class="order-total__item mb-12">
        <span>Subtotal</span>
        <span class="price">{{ orderSubtotal | price(currency, 4) }}</span>
      </div>
      <div class="order-total__item mb-12" v-if="invoice?.order?.orderDiscountAmount">
        <span>Discounts</span>
        <span class="price">{{ -discount | price(currency, 4) }}</span>
      </div>
      <div class="order-total__item mb-12" v-if="invoice?.order?.deliveryFee">
        <span>Delivery Fee</span>
        <span class="price">{{ invoice?.order?.deliveryFee | price(currency, 4) }}</span>
      </div>
      <div class="order-total__item mb-12" v-if="invoice?.order?.amountAdjustment">
        <span>Price Adjustment</span>
        <span class="price">{{ invoice?.order?.amountAdjustment | price(currency, 4) }}</span>
      </div>
      <div class="order-total__item mb-12">
        <span class="tax-label">
          Tax {{ taxValue !== null ? `(${taxValue}%)` : '' }}
          <v-status-dropdown
            class="tax-dropdown ml-8 mt-4"
            @change="updateTaxCalculation"
            :data="taxCalculationOptions"
          />
        </span>
        <span class="price">{{ taxFee | price(currency, 4) }}</span>
      </div>
      <div class="order-total__item order-total__item--total">
        <span>Total</span>
        <span class="price">{{ total | price(currency, 4) }}</span>
      </div>
    </div>
  </div>
</template>

<style scoped lang="scss">
.order-calculations {
  display: grid;
  grid-template-columns: 50% 50%;
  justify-content: stretch;

  .order-adjustments {
    margin-right: 12px;
    max-width: 380px;
    text-align: left;

    &__label {
      margin-bottom: 4px;
      font-size: 12px;
      line-height: 1.5;
      color: $color-gray-6C;
    }

    :deep() .mark-input--inline {
      width: 100%;
      border: 1px solid #eceef5;
      border-radius: 3px;
      padding: 8px 14px;

      .mask-input__input {
        border-bottom: none;
        text-align: left;
        color: $color-gray-6C;
        font-weight: 400;
        padding-left: 20px;
      }

      .mask-input__prefix {
        left: 12px;
        top: 50%;
        transform: translateY(-50%);
        color: $color-gray-6C;
      }
    }
  }

  .order-total {
    margin-left: 12px;
    width: 100%;
    max-width: 380px;
    text-align: left;

    justify-self: right;

    &__item {
      display: flex;
      justify-content: space-between;

      font-size: 14px;
      line-height: 1.4;
      color: $color-gray-6C;

      &--total {
        font-size: 16px;
        font-weight: 500;
      }

      .price {
        font-size: 16px;
        font-weight: 500;
        color: $color-gray-25;
      }
    }
    .tax-label {
      display: flex;
    }
    .tax-dropdown {
      :deep() .ez-simple-dropdown__display-container {
        display: flex;
      }
      :deep() .ez-simple-dropdown__dropdown {
        padding: 0;
        li {
          cursor: pointer;
          &:hover {
            background-color: $color-gray-F5;
          }
        }
      }
    }
  }
}
</style>
