<script>
/**
   * Generic component for encapsulating entity info.
   * Able to render entity image as `div` with background.
   * Also, its default slot is intended for rendering additional entity info (eg: name, status...)
   *
   * @version 1.0.0
   */
export default {
  props: {
    /**
       * Entity image size
       */
    imageSize: {
      type: String,
      required: false,
      default: '32px',
    },
    /**
       * Entity image url
       */
    imageUrl: {
      type: String,
      required: false,
      default: '',
    },
    /**
       * Entity image url
       */
    imageHasBorder: {
      type: Boolean,
      required: false,
      default: true,
    },
    /**
       * Entity image border radius
       */
    imageBorderRadius: {
      type: String,
      required: false,
      default: '4px',
    },
  },
  computed: {
    hasInfo() {
      return !!this.$slots.default;
    },
    previewStyle() {
      return {
        backgroundImage: this.imageUrl ? `url('${this.imageUrl}')` : null,
        height: this.imageSize,
        width: this.imageSize,
        borderRadius: this.imageBorderRadius,
      };
    },
  },
  methods: {
    onPreviewClick() {
      /**
         * When preview image is clicked
         * @event previewClick
         */
      this.$emit('previewClick');
    },
  },
};
</script>

<template>
  <div class="entity-info">
    <div
      :style="previewStyle"
      :class="{ 'entity-info__preview': true, 'has-border': imageHasBorder }"
      @click="onPreviewClick"
    ></div>
    <div v-if="hasInfo" class="entity-info__info">
      <span class="entity-info__text"><slot/></span>
      <span class="entity-info__suffix"><slot name="suffix"/></span>
    </div>
  </div>
</template>

<style scoped lang="scss">
  $preview-bg-color: #F1F4F7;
  $preview-border-color: #DEE1E4;
  $text-color: #252631;

  .entity-info {
    display: inline-flex;
    align-items: stretch;
    max-width: 100%;
    vertical-align: middle;

    &__preview {
      background-color: $preview-bg-color;
      background-repeat: no-repeat;
      background-size: cover;
      background-position: center;
      flex-shrink: 0;

      &.has-border {
        border: 1px solid $preview-border-color;
      }
    }
    &__info {
      @extend %flex-center;
      margin-left: .5rem;
      color: $text-color;
      width: 100%;
      overflow-x: hidden;
      white-space: nowrap;
    }

    &__text {
      white-space: initial;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }
</style>
