<template>
  <div class="admin-categories">
    <header>
      <h1>Categories</h1>
      <ez-button @click="openCreateModal">
        Add New Category
      </ez-button>
    </header>
    <ez-table
      :data="categories"
      :columns="['name', 'subCount', 'productCount']"
      :headers="headers"
      :columnProps="{
        subCount: { class: 'medium-cell' },
        productCount: { class: 'medium-cell' },
      }"
      @rowClick="openSingleCategory"
    >
      <template #cell-name="{ row }">
        <ez-entity-info
          :imgUrl="row.image">
          <div class="category-info">
            <div class="category-info__name" :title="row.name">{{ row.name }}</div>
          </div>
        </ez-entity-info>
      </template>
      <template #cell-subCount="{ row }">
        <div style="text-align: right">{{ (row.children.length || 0).toLocaleString() }}</div>
      </template>
      <template #cell-productCount="{ row }">
        <div style="text-align: right">{{ (row.productCount || 0).toLocaleString() }}</div>
      </template>
    </ez-table>

    <ez-form-modal
      ref="createCategoryModal"
      class="ez-user-modal">
      <template #title>
        <slot name="title">Add New Category</slot>
      </template>
      <template #content>
        <ez-form
          action="/admin/categories"
          submitType="multipart"
          :formKey="createFormKey"
          @success="onCreateFormSubmit"
          ref="createCategoryForm"
          class="category-form"
        >
          <ez-image-upload
            :formKey="createFormKey"
            :previewUrl="defaultImage"
            name="image"
          />
          <ez-input
            placeholder="Enter Category Name"
            :formKey="createFormKey"
            label="Category name"
            name="name"
          />
        </ez-form>
      </template>
      <template #footer>
        <ez-button type="link" formType="button" @click="closeCreateModal">Cancel</ez-button>
        <ez-button formType="button" @click="addNewCategory">Add category</ez-button>
      </template>
    </ez-form-modal>

    <ez-confirmation-modal ref="deleteModal" type="red">
      <template #title>Remove category?</template>
      <template #content>
        <p>All related products will remain uncategorized.</p>
      </template>
      <template #footer>
        <ez-button @click="closeDeleteModal" type="link">Cancel</ez-button>
        <ez-button @click="confirmDeleteCategory" type="red">Remove category</ez-button>
      </template>
    </ez-confirmation-modal>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import flash from '@/components/ui/FlashMessage';
import EzButton from '@/components/ui/Button';
import { EzConfirmationModal, EzFormModal } from '@/components/ui/Modal';
import EzEntityInfo from '@/components/ui/EntityInfo';
import EzForm from '@/components/ui/Form';
import EzImageUpload from '@/components/ui/ImageUpload';
import EzInput from '@/components/ui/Input';
import EzTable from '@/components/ui/Table';
import Category from '@/models/Category';

export default {
  components: {
    EzButton,
    EzConfirmationModal,
    EzFormModal,
    EzEntityInfo,
    EzForm,
    EzImageUpload,
    EzInput,
    EzTable,
  },
  data() {
    return {
      headers: {
        name: h => h('span', null, 'Category Name'),
        subCount: h => h('div', { style: { textAlign: 'right' } }, '# of Subcategories'),
        productCount: h => h('div', { style: { textAlign: 'right' } }, '# of products'),
      },
      createFormKey: 'create-category',
      editFormKey: 'edit-category',

      editRowId: -1,
    };
  },
  computed: {
    ...mapGetters('defaultImages', [
      'getDefaultImage',
    ]),
    editRow() {
      return Category.find(this.editRowId) || {};
    },
    categories() {
      return Category.query()
        .with('children')
        .where('parentId', null)
        .all();
    },
    defaultImage() {
      return this.getDefaultImage('category');
    },
  },
  methods: {
    onCreateFormSubmit({ data }) {
      Category.insert({ data: data.data })
        .then(() => {
          this.closeCreateModal();
          flash.success({
            title: 'Category successfully added!',
          });
        });
    },
    openCreateModal() {
      this.$refs.createCategoryModal.open();
    },
    closeCreateModal() {
      this.$refs.createCategoryModal.close();
    },
    addNewCategory() {
      this.$refs.createCategoryForm.onSubmit();
    },
    onEditFormSubmit({ data }) {
      Category.update({ data: data.data })
        .then(() => {
          this.closeEditDrawer();
          flash.success({
            title: 'Category successfully updated!',
          });
        });
    },
    openSingleCategory({ id, name }) {
      this.$router.push({
        name: 'admin-categories-single',
        params: {
          id,
          name,
        },
      });
    },

    closeEditDrawer() {
      this.$refs.editCategoryDrawer.close();
    },
    onRemoveCategory() {
      this.$refs.deleteModal.open();
    },
    onRemoveCategoryImage() {
      const { image } = this.editRow;

      if (!image) {
        return;
      }

      this.removeCategoryImage(this.editRow);
    },
    confirmDeleteCategory() {
      const { id } = this.editRow;

      if (!id) {
        return;
      }

      this.removeCategory(id)
        .then(() => {
          this.closeDeleteModal();

          this.closeEditDrawer();

          flash.success({
            title: 'Category successfully removed!',
          });

          this.editRowId = -1;
        });
    },
    closeDeleteModal() {
      this.$refs.deleteModal.close();
    },
    ...mapActions('entities/categories', [
      'removeCategory',
      'removeCategoryImage',
    ]),
  },
};
</script>

<style scoped lang="scss">
  .admin-categories {
    @extend %list;

    .category-form {
      max-width: 26rem;
      margin: 1.5rem auto;

      .ez-image-upload {
        margin-bottom: 1rem;
      }
    }

    .category-info {
      @include name-status-group();
      justify-content: center;
    }

    .edit-actions {
      display: flex;

      .remove-category {
        margin-right: auto;
      }
    }
  }
</style>
