<template>
  <single-page class="admin-categories">
    <template #breadcrumbs>
      <router-link :to="{ name: 'admin-categories' }">
        <font-awesome-icon icon="arrow-left"/>
        Back to categories
      </router-link>
    </template>
    <template #title>
      <h1>{{ categoryName }}</h1>
    </template>

    <template #actions>
      <ez-button @click="openCreateModal">
        Add New Subcategory
      </ez-button>
      <ez-button-dropdown buttonType="secondary">
        <template #icon>
          <font-awesome-icon icon="ellipsis-h"/>
        </template>
        <template #dropdown>
          <ez-button
            @click="editParentCategory"
            type="link">Edit Category
          </ez-button>
          <ez-button
            v-open="'moveProducts'"
            type="link">Move Products
          </ez-button>
          <ez-button
            @click="onDeleteParentCategory"
            type="red-link">Delete Category
          </ez-button>
        </template>
      </ez-button-dropdown>
    </template>

    <ez-table
      :data="categories"
      :columns="['name', 'productCount']"
      :columnProps="{ productCount: { class: 'count-cell' } }"
      :headers="headers"
      :removeButton="true"
      @removeItem="openConfirmDeleteSubcategory"
      @rowClick="openSubcategoryEdit"
    >
      <template #cell-name="{ row }">
        <ez-entity-info
          :imgUrl="row.image">
          <div class="category-info">
            <div class="category-info__name" :title="row.name">{{ row.name }}</div>
          </div>
        </ez-entity-info>
      </template>
      <template #cell-productCount="{ row }">
        {{ (row.productCount || 0).toLocaleString() }}
      </template>
    </ez-table>

    <ez-form-modal class="edit-modal" ref="editParentCategory" @close="resetImgState">
      <template #title>
        <slot name="title">Edit Category</slot>
      </template>
      <template #content>
        <ez-form
          :action="`/admin/categories/${editParentCategoryCopy.id}`"
          method="patch"
          submitType="multipart"
          :additional-data="{...(imgModified && {image: ''})}"
          :formKey="editFormKey"
          @success="onEditFormSubmit"
          ref="editCategoryForm"
          class="category-form">
          <ez-image-upload
            ref="parentCatImgUpload"
            :formKey="editFormKey"
            :previewUrl="editParentCategoryCopy.image || defaultImage"
            @change="imgModified = true"
            :isDefaultImage="editParentCategoryCopy.isDefaultImage"
            @removeImage="onRemoveCategoryImage"
            name="image"></ez-image-upload>
          <ez-input
            :formKey="editFormKey"
            placeholder="Enter Category Name"
            :value="editParentCategoryCopy.name"
            label="Category name"
            name="name"></ez-input>
          <!-- TODO: Backend should fix this. This shouldn't be required -->
          <ez-input
            type="hidden"
            :form-key="editFormKey"
            value=""
            name="parentId"></ez-input>
        </ez-form>
      </template>

      <template #footer>
        <ez-button
          type="link"
          formType="button"
          @click="closeEditParentCategory">Cancel</ez-button>
        <ez-button
          formType="button"
          @click="updateCategory">Update Changes</ez-button>
      </template>
    </ez-form-modal>

    <ez-form-modal class="edit-modal" ref="editSubcategory" @close="resetImgState">
      <template #title>
        <slot name="title">Edit Category</slot>
      </template>
      <template #content>
        <ez-form
          :action="`/admin/categories/${selectedSubCategory.id}`"
          method="patch"
          submitType="multipart"
          :additional-data="{...(imgModified && {image: ''})}"
          :formKey="editSubFormKey"
          @success="onEditSubFormSubmit"
          ref="editSubCategoryForm"
          class="category-form">
          <ez-image-upload
            ref="subcatImgUpload"
            :formKey="editSubFormKey"
            :previewUrl="selectedSubCategory.image || defaultImage"
            @change="imgModified = true"
            :isDefaultImage="selectedSubCategory.isDefaultImage"
            @removeImage="onRemoveSubCategoryImage(selectedSubCategory)"
            name="image"/>
          <ez-select
            ref="select"
            class="input-group edit-subcategory-select"
            name="parentId"
            :value="parentCategory"
            label="Parent Category"
            :options="parentCategories"
            @change="setNewParentId"
          >
            <ul>
              <li
                v-for="option in parentCategories"
                :key="option.id">
                <ez-option :option="option">{{ option.name }}</ez-option>
              </li>
            </ul>
          </ez-select>
          <ez-input
            :formKey="editSubFormKey"
            :value="selectedSubCategory.name"
            placeholder="Enter Category Name"
            label="Category name"
            name="name"></ez-input>
        </ez-form>
      </template>

      <template #footer>
        <ez-button
          type="link"
          formType="button"
          @click="closeEditSubCategory">Cancel</ez-button>
        <ez-button
          formType="button"
          @click="updateSubCategory">Update Changes</ez-button>
      </template>
    </ez-form-modal>

    <ez-form-modal
      ref="createCategoryModal"
      class="ez-user-modal">
      <template #title>
        <slot name="title">Add New Category</slot>
      </template>
      <template #content>
        <ez-form
          action="/admin/categories"
          submitType="multipart"
          :formKey="createFormKey"
          @success="onCreateFormSubmit"
          ref="createCategoryForm"
          class="category-form">
          <ez-image-upload
            :formKey="createFormKey"
            :previewUrl="defaultImage"
            name="image"></ez-image-upload>
          <ez-input
            :formKey="createFormKey"
            placeholder="Enter Category Name"
            label="Category name"
            name="name"></ez-input>
          <!-- TODO: Backend should fix this. This shouldn't be required -->
          <ez-input
            :form-key="createFormKey"
            placeholder="Enter Category Name"
            name="parentId"
            type="hidden"
            :value="parentCategory.id"></ez-input>
        </ez-form>
      </template>
      <template #footer>
        <ez-button
          type="link"
          formType="button"
          @click="closeCreateModal">Cancel</ez-button>
        <ez-button
          formType="button"
          @click="addNewCategory">Add category</ez-button>
      </template>
    </ez-form-modal>

    <ez-confirmation-modal
      ref="moveProducts"
      type="blue"
      @close="resetMoveProducts"
    >
      <template #title>Move products to another category</template>
      <template #content>
        <p>Move all products from one subcategory to another subcategory.<br>
          Select from which subcategory you want to move <strong>all</strong> products.</p>
        <ez-dropdown
          ref="fromInput"
          name="account"
          label="From"
          class="mb-24"
          :data="categories"
          is-full-width
          placeholder="Subcategory to move products from"
          @change="(c) => moveCategory.from = c.id"
        />

        <ez-category-filter
          ref="toInput"
          label="To"
          is-full-width
          :is-parent-disabled="true"
          name="categoryId"
          @change="(c) => moveCategory.to = c.id"
        />
      </template>
      <template #footer>
        <ez-button v-close="'moveProducts'" type="link">Cancel</ez-button>
        <ez-button
          @click="moveProducts"
          :disabled="!moveCategory.to || !moveCategory.from"
        >Move Products</ez-button>
      </template>
    </ez-confirmation-modal>

    <ez-confirmation-modal ref="deleteConfirmation" type="red" innerClasses="modal__inner--delete">
      <template #title>Delete Category?</template>
      <template #content>
        <p>When deleted, all subcategories in this category will be deleted and products
        in them left uncategorized.</p>
      </template>
      <template #footer>
        <ez-button @click="closeDeleteModal" type="link">Cancel</ez-button>
        <ez-button @click="confirmDeleteCategory" type="red">Delete</ez-button>
      </template>
    </ez-confirmation-modal>

    <ez-confirmation-modal ref="confirmDeleteSubcategory"
                           type="red"
                           innerClasses="modal__inner--delete">
      <template #title>Delete Category?</template>
      <template #content>
        <p>When deleted, all products in the category will be left uncategorized.</p>
      </template>
      <template #footer>
        <ez-button @click="closeConfirmDeleteSubcategory" type="link">Cancel</ez-button>
        <ez-button @click="confirmDeleteSubcategory" type="red">Delete</ez-button>
      </template>
    </ez-confirmation-modal>
  </single-page>
</template>
<script>
import { mapActions, mapGetters } from 'vuex';
import EzButton from '@/components/ui/Button';
import EzButtonDropdown from '@/components/ui/ButtonDropdown';
import EzEntityInfo from '@/components/ui/EntityInfo';
import SinglePage from '@/views/admin/SinglePage.vue';
import EzTable from '@/components/ui/Table';
import EzForm from '@/components/ui/Form';
import EzImageUpload from '@/components/ui/ImageUpload';
import EzInput from '@/components/ui/Input';
import EzSelect, { EzOption } from '@/components/ui/Select';
import { EzFormModal, EzConfirmationModal } from '@/components/ui/Modal';
import flash from '@/components/ui/FlashMessage';
import Category from '@/models/Category';
import EzCategoryFilter from '@/components/ui/Filter/Category.vue';
import EzDropdown from '@/components/ui/Dropdown/EzDropdown';
import httpService from '@/api/http';

export default {
  components: {
    EzTable,
    EzButton,
    SinglePage,
    EzForm,
    EzOption,
    EzImageUpload,
    EzInput,
    EzSelect,
    EzFormModal,
    EzEntityInfo,
    EzButtonDropdown,
    EzConfirmationModal,
    EzCategoryFilter,
    EzDropdown,
  },
  props: {
    parentId: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      newSubCategoryParentId: null,
      selectedSubCategoryId: -1,
      selectedSubCategory: {},
      editParentCategoryCopy: {},
      subCategoryToDelete: null,
      imgModified: false,
      createFormKey: 'add-subcategory',
      editFormKey: 'edit-category',
      editSubFormKey: 'edit-subcategory',
      headers: {
        name: h => h('span', null, 'Subcategory Name'),
        productCount: h => h('div', { style: { textAlign: 'right' } }, '# of products'),
      },
      moveCategory: {
        from: null,
        to: null,
      },
    };
  },
  computed: {
    ...mapGetters('defaultImages', [
      'getDefaultImage',
    ]),
    parentCategory() {
      return Category.query()
        .with('children')
        .find(this.parentId);
    },
    defaultImage() {
      return this.getDefaultImage('category');
    },
    parentCategories() {
      return Category.query()
        .where('parentId', null)
        .all();
    },
    subCategoryParentId() {
      return this.newSubCategoryParentId || (this.parentCategory || {}).id || -1;
    },
    categoryName() {
      return (this.parentCategory || {}).name || '';
    },
    categories() {
      return (this.parentCategory || {}).children || [];
    },
  },
  methods: {
    ...mapActions('entities/categories', [
      'removeCategory',
      'removeCategoryImage',
      'fetchCategoryById',
      'fetchCategories',
    ]),
    openCreateModal() {
      this.$refs.createCategoryModal.open();
    },
    closeCreateModal() {
      this.$refs.createCategoryModal.close();
    },
    onCreateFormSubmit({ data }) {
      Category.insert({ data: { ...data.data, parentId: this.parentCategory.id } }).then(() => {
        this.closeCreateModal();
        flash.success({
          title: 'Category successfully added!',
        });
      });
    },
    addNewCategory() {
      this.$refs.createCategoryForm.onSubmit();
    },
    onDeleteParentCategory() {
      this.$refs.deleteConfirmation.open();
    },
    closeDeleteModal() {
      this.$refs.deleteConfirmation.close();
    },
    async deleteCategory({ id }) {
      if (!id) return;
      await this.removeCategory(id);
      flash.success({
        title: 'Category successfully removed!',
      });
    },
    async confirmDeleteCategory() {
      await this.deleteCategory(this.parentCategory);
      this.closeDeleteModal();
      this.$router.push({ name: 'admin-categories' });
    },

    async confirmDeleteSubcategory() {
      await this.deleteCategory(this.subCategoryToDelete);
      this.closeConfirmDeleteSubcategory();
      this.subCategoryToDelete = null;
    },
    openConfirmDeleteSubcategory(data) {
      this.subCategoryToDelete = data;
      this.$refs.confirmDeleteSubcategory.open();
    },
    closeConfirmDeleteSubcategory() {
      this.$refs.confirmDeleteSubcategory.close();
    },
    openSubcategoryEdit(data) {
      this.selectedSubCategoryId = data.id;
      this.selectedSubCategory = JSON.parse(JSON.stringify(Category.find(data.id)));
      this.$refs.editSubcategory.open();
    },
    onEditFormSubmit({ data }) {
      Category.update({ data: data.data })
        .then(() => {
          this.closeEditParentCategory();
          flash.success({
            title: 'Category successfully updated!',
          });
        });
    },
    setNewParentId({ id }) {
      this.newSubCategoryParentId = id;
    },
    onEditSubFormSubmit({ data }) {
      Category.update({ data: { ...data.data, parentId: this.subCategoryParentId } })
        .then(() => {
          this.closeEditSubCategory();
          flash.success({
            title: 'Category successfully updated!',
          });
        });
    },
    onRemoveCategoryImage() {
      this.imgModified = true;
      this.editParentCategoryCopy.isDefaultImage = true;
      this.editParentCategoryCopy.image = null;
    },
    onRemoveSubCategoryImage(subCategory) {
      this.imgModified = true;
      subCategory.isDefaultImage = true;
      subCategory.image = null;
    },
    editParentCategory() {
      this.editParentCategoryCopy = JSON.parse(JSON.stringify((this.parentCategory)));
      this.$refs.editParentCategory.open();
    },
    closeEditParentCategory() {
      this.$refs.editParentCategory.close();
    },
    closeEditSubCategory() {
      this.$refs.editSubcategory.close();
    },
    resetImgState() {
      this.imgModified = false;
      this.$refs.parentCatImgUpload.resetImageData();
      this.$refs.subcatImgUpload.resetImageData();
    },
    updateCategory() {
      this.$refs.editCategoryForm.onSubmit();
    },
    updateSubCategory() {
      this.$refs.editSubCategoryForm.onSubmit();
    },
    async moveProducts() {
      if (!this.moveCategory.from || !this.moveCategory.to) return;

      try {
        await httpService.put(`/admin/categories/${this.moveCategory.from}/switch/${this.moveCategory.to}`);
        this.fetchCategories();
        this.$refs.moveProducts.close();
        flash.success({
          title: 'Products successfully moved to another category.',
        });
      } catch (e) {
        flash.error({ title: 'Something went wrong' });
      }
    },
    resetMoveProducts() {
      const { fromInput, toInput } = this.$refs;
      fromInput.reset();
      toInput.reset();
    },
  },
};
</script>
<style scoped lang="scss">
  .admin-categories {
    @extend %list;

    :deep() .modal__inner--delete {
      padding-bottom: .875rem;
    }

    :deep() .modal__text {
      @include font-size(14px);
    }

    :deep() .button {
      &--red {
        margin-left: 1.5rem;
      }
    }

    .category-form {
      max-width: 100%;
      .ez-image-upload {
        margin-bottom: 1rem;
      }
    }

    .category-info {
      @include name-status-group();
      justify-content: center;
    }

    .edit-actions {
      display: flex;
      .remove-category {
        margin-right: auto;
      }
    }
  }

  .edit-subcategory-select {
    margin-bottom: 1em;
    &,
    :deep() .ez-select__display-container {
      width: 100%;
      max-width: 100%;
    }
  }
  .edit-modal {
    form {
      margin: 0;
    }
  }
</style>
